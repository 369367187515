/**
 * @constant {Object}
 */
export const URL_PATHS = {
  homePage: '/',
  catalogPage: '/catalog',
  labPage: '/lab',
  redirectPage: '/redirect',
  standaloneLabPage: '/sa/lab',
  standaloneSignOut: '/logout',
};
