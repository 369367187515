import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useLocation, Link } from 'react-router-dom';

import { ButtonOverride } from '../PolarisOverrides';
import { handleSignIn } from '../../utils/authUtils';
import { publishButtonClickMetric } from '../../utils/metrics';
import { URL_PATHS } from '../../constants/urlPaths';
import LocaleMenu from './LocaleMenu';
import UserMenu from './UserMenu';
import EventLogo from './EventLogo';
import HeaderLink from './HeaderLink';
import HeaderLayout from './HeaderLayout';
import messages from './Header.messages';
import { dataTestId } from '../../constants/dataTestIdSelectors';

/**
 * The full Event Horizon header used for the complete user experience.
 */
const HeaderFull = ({ isAuthenticated, currentUser, testid }) => {
  const { formatMessage } = useIntl();
  const location = useLocation();

  const branding = (
    <Link
      to={URL_PATHS.homePage}
      onClick={() =>
        publishButtonClickMetric('EventLogo', {
          currentPath: location.pathname,
        })
      }
    >
      <EventLogo />
    </Link>
  );

  const actions = (
    <>
      <HeaderLink
        to={URL_PATHS.homePage}
        onClick={() =>
          publishButtonClickMetric('RecommendationsLink', {
            currentPath: location.pathname,
          })
        }
        data-testid={dataTestId['header-recommendationsLink']}
      >
        {formatMessage(messages.recommendationsBtn)}
      </HeaderLink>
      <HeaderLink
        to={URL_PATHS.catalogPage}
        onClick={() =>
          publishButtonClickMetric('CatalogLink', {
            currentPath: location.pathname,
          })
        }
        data-testid={dataTestId['header-catalogLink']}
      >
        {formatMessage(messages.catalogBtn)}
      </HeaderLink>
      <LocaleMenu />
      {isAuthenticated ? (
        <UserMenu>
          {currentUser || formatMessage(messages.defaultGreeting)}
        </UserMenu>
      ) : (
        <ButtonOverride variant="transparent" onClick={handleSignIn}>
          {formatMessage(messages.signIn)}
        </ButtonOverride>
      )}
    </>
  );

  return <HeaderLayout branding={branding} actions={actions} testid={testid} />;
};

HeaderFull.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  currentUser: PropTypes.string,
  testid: PropTypes.string.isRequired,
};

export default HeaderFull;
