/**
 * @typedef {'Failed'|'In Progress'|'Not Started'|'Passed'|'Paused'} LabAssessmentStatus
 */

/**
 * Expected Lab Assessment statuses from Assessments Service
 * @readonly
 * @enum {LabAssessmentStatus}
 *
 * @link https://code.amazon.com/packages/AwsTcAssessmentsCommon/blobs/mainline/--/src/constants/userAssessmentConstants.ts
 */
export const LAB_ASSESSMENT_STATUS = {
  FAILED: 'Failed',
  IN_PROGRESS: 'In Progress',
  NOT_STARTED: 'Not Started',
  PASSED: 'Passed',
  PAUSED: 'Paused', // Not used
};

export const ASSESSMENT_UI_WINDOW_NAME = 'AssessmentUI';
