import React, { useMemo, lazy, Suspense, useLayoutEffect } from 'react';
import { Button, Box, Header } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { pipe, prop } from 'ramda';

import LoadingSkeleton from '../LoadingSkeleton';
import { TableOverride } from '../PolarisOverrides';
import CatalogListItem from './CatalogListItem';
import { useEventContext, useLabContext } from '../../contexts';
import { getLocaleOrAvailable } from '../../utils/localeUtils';
import messages from './Catalog.messages';
import ERROR_MESSAGES from '../../i18n/errors.messages';
import FEATURES from '../../constants/features';
import { EHBlueprint } from '../../models';

const CatalogSearch = lazy(() => import('./CatalogSearch'));

/**
 * Generates a list of CatalogListItem components to be shown in the catalog.
 * Invalid blueprints are removed from the list to avoid a empty card in the
 * UI. If no metadata for the preferred locale exists the first available
 * locale will be picked.
 *
 * @param {EHBlueprint[]} catalogList
 * @param {String} locale
 * @param {String} ongoingBlueprintArn Blueprint arn for ongoing lab
 * @param {String} ongoingLabId Lab id for ongoing lab
 * @param {String} searchText Text used to search
 * @param {Object[]} searchResult
 * @param {EHBlueprint} searchResult[].item
 * @param {number} searchResult[].score
 * @param {(blueprint: EHBlueprint) => boolean} matchesFilterQuery
 * @returns {Array[React.Node]} List of components
 */
const getCatalogViewItems = (
  catalogList,
  locale,
  ongoingBlueprintArn,
  ongoingLabId,
  searchText,
  searchResult,
  matchesFilterQuery
) => {
  if (searchResult) {
    return searchResult
      .filter(pipe(prop('item'), matchesFilterQuery))
      .map(({ item: blueprint, score }) => {
        return (
          <CatalogListItem
            key={blueprint.arn}
            isLoading={false}
            blueprint={blueprint}
            bpLocale={getLocaleOrAvailable(locale, blueprint)}
            ongoingBlueprintArn={ongoingBlueprintArn}
            ongoingLabId={ongoingLabId}
            searchScore={score}
            searchText={searchText}
          />
        );
      });
  }
  return catalogList.filter(matchesFilterQuery).map(blueprint => {
    return (
      <CatalogListItem
        key={blueprint.arn}
        isLoading={false}
        blueprint={blueprint}
        bpLocale={getLocaleOrAvailable(locale, blueprint)}
        ongoingBlueprintArn={ongoingBlueprintArn}
        ongoingLabId={ongoingLabId}
      />
    );
  });
};

const EmptyCatalogView = ({ title, message }) => (
  <Box textAlign="center" color="inherit">
    <Box fontWeight="bold" margin={{ bottom: 'xxs' }} padding={{ top: 'xxs' }}>
      {title}
    </Box>
    <Box variant="p" margin={{ bottom: 'xs' }}>
      {message}
    </Box>
  </Box>
);

const CatalogLoadError = ({ onRefresh, loadErrorMsg, reloadBtnText }) => (
  <Box
    textAlign="center"
    padding={{ vertical: 'xs' }}
    margin={{ bottom: 'xs' }}
  >
    <p>{loadErrorMsg}</p>
    <Button onClick={onRefresh} iconName="refresh">
      {reloadBtnText}
    </Button>
  </Box>
);

const Catalog = ({
  isLoading,
  isError,
  catalogList,
  localePref,
  refinerState,
  setRefinerState,
}) => {
  const { formatMessage } = useIntl();
  const { event } = useEventContext();
  const { getFirstLabInLabState } = useLabContext();
  const labState = getFirstLabInLabState();

  // This is a hacky fix for an A11y finding.
  // If CloudScape does not plan to support this, we should use the Cards component instead.
  // See: https://sim.amazon.com/issues/EventHorizon-3482
  useLayoutEffect(() => {
    document
      .querySelector('div[data-testid="catalog-list"]')
      .querySelectorAll('table[role="table"]')
      .forEach(table => table.setAttribute('role', 'presentation'));
  }, []);

  const catalogViewItems = useMemo(
    () =>
      getCatalogViewItems(
        catalogList,
        localePref,
        labState.blueprintArn,
        labState.labId,
        refinerState.searchText,
        refinerState.searchResult,
        refinerState.matchesFilterQuery
      ),
    [
      catalogList,
      localePref,
      labState.blueprintArn,
      labState.labId,
      refinerState.searchText,
      refinerState.searchResult,
      refinerState.matchesFilterQuery,
    ]
  );

  const counterLabel =
    catalogList.length === 0
      ? ''
      : catalogViewItems.length === catalogList.length
        ? ` (${catalogViewItems.length})`
        : ` (${catalogViewItems.length} ${formatMessage(
            messages.headerCountSeparator
          )} ${catalogList.length})`;

  return (
    <TableOverride
      columnHeaders={false}
      wrapLines={true}
      header={
        <span role="status">
          <Header variant="h2" headingTagOverride="h1" counter={counterLabel}>
            {formatMessage(messages.title)}
          </Header>
        </span>
      }
      stickyHeader={true}
      data-testid="catalog-list"
      items={
        isError
          ? [
              <CatalogLoadError
                key={1}
                onRefresh={() => window.location.reload()}
                loadErrorMsg={formatMessage(ERROR_MESSAGES.loadCatalogFail)}
                reloadBtnText={formatMessage(messages.reload)}
              />,
            ]
          : isLoading
            ? Array(10)
                .fill()
                .map((_, i) => <CatalogListItem key={i} isLoading />)
            : catalogViewItems
      }
      columnDefinitions={[
        {
          cell: item => item,
          header: formatMessage(messages.labHeader),
        },
      ]}
      empty={
        catalogList.length === 0 ? (
          <EmptyCatalogView
            title={formatMessage(messages.emptyViewTitle)}
            message={formatMessage(messages.emptyViewMessage)}
          />
        ) : (
          <EmptyCatalogView
            title={formatMessage(messages.filteringNoMatchesLineOne)}
            message={formatMessage(messages.filteringNoMatchesLineTwo)}
          />
        )
      }
      filter={
        event.hasFeature(FEATURES.catalogSearch) && (
          <Suspense fallback={<LoadingSkeleton width={100} height={32} />}>
            <CatalogSearch
              catalogList={catalogList}
              setRefinerState={setRefinerState}
            />
          </Suspense>
        )
      }
    />
  );
};

Catalog.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  catalogList: PropTypes.arrayOf(PropTypes.instanceOf(EHBlueprint)),
  localePref: PropTypes.string,
  refinerState: PropTypes.object.isRequired,
  setRefinerState: PropTypes.func.isRequired,
};

export default Catalog;
