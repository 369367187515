/**
 * When adding a new data test ID, consider if it should be added to
 * capture click stream event data as well in `clickStreamSelectors`.
 */
export const dataTestId = {
  ['carousel-left']: 'carousel-left',
  ['carousel-link']: 'carousel-link',
  ['carousel-right']: 'carousel-right',
  ['catalog-cta']: 'catalog-cta',
  ['catalog-listItemLink']: 'catalog-listItemLink',
  ['channel-container']: 'channel-container',
  ['feedback-close']: 'feedback-close',
  ['feedback-open']: 'feedback-open',
  ['feedback-submit']: 'feedback-submit',
  ['feedback-surveyCta']: 'feedback-surveyCta',
  ['header-catalogLink']: 'header-catalogLink',
  ['header-localeMenu']: 'header-localeMenu',
  ['header-recommendationsLink']: 'header-recommendationsLink',
  ['header-userMenu']: 'header-userMenu',
  ['helpPanel-open']: 'helpPanel-open',
  ['helpPanel-tourLaunch']: 'helpPanel-tourLaunch',
  ['helpPanel-customerSupport']: 'helpPanel-customerSupport',
  ['lab-endBtn']: 'lab-endBtn',
  ['lab-endConfirmBtn']: 'lab-endConfirmBtn',
  ['lab-openConsoleBtn']: 'lab-openConsoleBtn',
  ['lab-startAcceptBtn']: 'lab-startAcceptBtn',
  ['lab-startAcceptChbx']: 'lab-startAcceptChbx',
  ['lab-startBtn']: 'lab-startBtn',
  ['lab-startDialog']: 'lab-startDialog',
  ['lab-toc']: 'lab-toc',
  ['lab-resources']: 'lab-resources',
  ['lab-readyNotification']: 'lab-readyNotification',
  ['lab-progressTrackingBtn']: 'lab-progressTrackingBtn',
  ['lab-progressTrackingConfirmBtn']: 'lab-progressTrackingConfirmBtn',
  ['ongoing-endLabBtn']: 'ongoing-endLabBtn',
  ['ongoing-existingLabLink']: 'ongoing-existingLabLink',
  ['ongoing-resumeLabBtn']: 'ongoing-resumeLabBtn',
  ['siteTour-back']: 'siteTour-back',
  ['siteTour-next']: 'siteTour-next',
  ['siteTour-skip']: 'siteTour-skip',
  ['spotlight-link']: 'spotlight-link',
  ['liveSupport-inPersonHeader']: 'liveSupport-inPersonHeader',
  ['liveSupport-chatHeader']: 'liveSupport-chatHeader',
  ['footer-eventLink']: 'footer-eventLink',
  ['labEval-submit']: 'labEval-submit',
  ['labEval-evaluate']: 'labEval-evaluate',
  ['labEval-retryEvaluate']: 'labEval-retryEvaluate',
  ['labEval-evaluateScoreHeading']: 'labEval-evaluateScoreHeading',
  ['labEval-evaluateResultsDetailsHeader']:
    'labEval-evaluateResultsDetailsHeader',
  ['labEval-evaluationPercentageScore']: 'labEval-evaluationPercentageScore',
  ['lab-labEvaluationPanelBtn']: 'lab-labEvaluationPanelBtn',
  ['supportInfo-copySupportInfo']: 'supportInfo-copySupportInfo',
  ['footer-tncLogo']: 'footer-tncLogo',
  ['footer-privacy']: 'footer-privacy',
  ['footer-siteTerms']: 'footer-siteTerms',
  ['footer-cookies']: 'footer-cookies',
  ['calloutContainer-cta']: 'calloutContainer-cta',
  ['calloutTopic-lab']: 'calloutTopic-lab',
  ['calloutTopic-jam']: 'calloutTopic-jam',
  ['ai-chatbot']: 'ai-chatbot',
  ['ai-chatbot-promobanner-eh']: 'ai-chatbot-promobanner-eh',
  ['active-lab-only-error']: 'active-lab-only-error',
  ['labAssessment-launch']: 'labAssessment-launch',
  ['labAssessment-resume']: 'labAssessment-resume',
  ['labAssessment-retake']: 'labAssessment-retake',
};

const dataSelector = selector => `[data-testid="${selector}"]`;

/**
 * The following RUM interaction selectors provide click stream events when
 * customers interacts with the EH application.
 *
 * Note: RUM does not support wildcard selectors at the moment so we need to
 * be very specific on what to collect.
 * Docs: https://github.com/aws-observability/aws-rum-web/blob/main/docs/configuration.md#interaction
 */
export const clickStreamSelectors = [
  { event: 'click', cssLocator: dataSelector(dataTestId['carousel-left']) },
  { event: 'click', cssLocator: dataSelector(dataTestId['carousel-link']) },
  { event: 'click', cssLocator: dataSelector(dataTestId['carousel-right']) },
  { event: 'click', cssLocator: dataSelector(dataTestId['catalog-cta']) },
  {
    event: 'click',
    cssLocator: dataSelector(dataTestId['catalog-listItemLink']),
  },
  { event: 'click', cssLocator: dataSelector(dataTestId['feedback-close']) },
  { event: 'click', cssLocator: dataSelector(dataTestId['feedback-open']) },
  { event: 'click', cssLocator: dataSelector(dataTestId['feedback-submit']) },
  {
    event: 'click',
    cssLocator: dataSelector(dataTestId['feedback-surveyCta']),
  },
  {
    event: 'click',
    cssLocator: dataSelector(dataTestId['header-catalogLink']),
  },
  { event: 'click', cssLocator: dataSelector(dataTestId['header-localeMenu']) },
  {
    event: 'click',
    cssLocator: dataSelector(dataTestId['header-recommendationsLink']),
  },
  { event: 'click', cssLocator: dataSelector(dataTestId['header-userMenu']) },
  { event: 'click', cssLocator: dataSelector(dataTestId['helpPanel-open']) },
  {
    event: 'click',
    cssLocator: dataSelector(dataTestId['helpPanel-tourLaunch']),
  },
  {
    event: 'click',
    cssLocator: dataSelector(dataTestId['helpPanel-customerSupport']),
  },
  { event: 'click', cssLocator: dataSelector(dataTestId['lab-endBtn']) },
  { event: 'click', cssLocator: dataSelector(dataTestId['lab-endConfirmBtn']) },
  {
    event: 'click',
    cssLocator: dataSelector(dataTestId['lab-openConsoleBtn']),
  },
  {
    event: 'click',
    cssLocator: dataSelector(dataTestId['lab-startAcceptBtn']),
  },
  {
    event: 'click',
    cssLocator: dataSelector(dataTestId['lab-startAcceptChbx']),
  },
  { event: 'click', cssLocator: dataSelector(dataTestId['lab-startBtn']) },
  { event: 'click', cssLocator: dataSelector(dataTestId['ongoing-endLabBtn']) },
  {
    event: 'click',
    cssLocator: dataSelector(dataTestId['ongoing-existingLabLink']),
  },
  {
    event: 'click',
    cssLocator: dataSelector(dataTestId['ongoing-resumeLabBtn']),
  },
  { event: 'click', cssLocator: dataSelector(dataTestId['siteTour-back']) },
  { event: 'click', cssLocator: dataSelector(dataTestId['siteTour-next']) },
  { event: 'click', cssLocator: dataSelector(dataTestId['siteTour-skip']) },
  { event: 'click', cssLocator: dataSelector(dataTestId['spotlight-link']) },
  { event: 'click', cssLocator: dataSelector(dataTestId['labEval-evaluate']) },
  {
    event: 'click',
    cssLocator: dataSelector(dataTestId['labEval-retryEvaluate']),
  },
  { event: 'click', cssLocator: dataSelector(dataTestId['labEval-submit']) },
  {
    event: 'click',
    cssLocator: dataSelector(dataTestId['lab-labEvaluationPanelBtn']),
  },
  {
    event: 'click',
    cssLocator: dataSelector(dataTestId['supportInfo-copySupportInfo']),
  },
  {
    event: 'click',
    cssLocator: dataSelector(dataTestId['calloutContainer-cta']),
  },
  {
    event: 'click',
    cssLocator: dataSelector(dataTestId['calloutTopic-lab']),
  },
  {
    event: 'click',
    cssLocator: dataSelector(dataTestId['calloutTopic-jam']),
  },
  {
    event: 'click',
    cssLocator: dataSelector(dataTestId['labAssessment-launch']),
  },
  {
    event: 'click',
    cssLocator: dataSelector(dataTestId['labAssessment-resume']),
  },
  {
    event: 'click',
    cssLocator: dataSelector(dataTestId['labAssessment-retake']),
  },
];
