import * as KatalMetrics from '@amzn/katal-metrics';
import KatalMetricsDriverSushi from '@amzn/katal-metrics-driver-sushi';
import KatalMetricsDriverArrayCollector from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverArrayCollector';
import KatalMetricsDriverConsoleLogJson from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson';
import {
  prop,
  propEq,
  propOr,
  take,
  forEachObjIndexed,
  path,
  has,
  includes,
  isEmpty,
} from 'ramda';
import { AwsRum } from 'aws-rum-web';
import { Hub } from '@aws-amplify/core';

import configService from './configService';
import logger from './logger';
import { isDevelopment } from './envUtil';
import { EH_FATAL_COUNTER, IMPRESSIONS } from '../constants/metrics';
import { hasShortbreadConsent } from './cookieManagement';
import { RUM_SESSION, RUM_USER } from '../constants/cookies';
import { COOKIE_CONSENT_CHANGED } from '../constants/pubSub';
import { createWrappedError } from '../errors/WrappedError';
import { clickStreamSelectors } from '../constants/dataTestIdSelectors';
import ERROR_TYPES from '../constants/errorTypes';

// String metrics are limited to a certain length.
const METRIC_STRING_MAX = 256;
const NAME_STRING_MAX = 127;

/**
 * Helper method to prepare a string metric, for example truncating the length
 * and stringifying when needed.
 *
 * @param {String} [value] String metric to be prepared
 * @param {Number} [maxChar] (optional) Maximum number of characters, 256 is default
 * @param {Boolean} [showEllipsis] (optional) Flag to show ellipsis, true is default
 * @returns {String}
 */
const prepareStringMetric = (
  value = '',
  maxChar = METRIC_STRING_MAX,
  showEllipsis = true
) => {
  let stringVal = typeof value === 'string' ? value : JSON.stringify(value);
  if (!stringVal || prop('length', stringVal) < maxChar) return stringVal;
  return showEllipsis
    ? `${take(maxChar - 1, stringVal)}…`
    : take(maxChar, stringVal);
};

const SITE_NAME = 'EventHorizon';
const SERVICE_NAME = 'WebApp';

const eventName = isDevelopment
  ? 'localhost'
  : window.location.hostname.replace(/\./g, '-');
const eventServiceName = prepareStringMetric(
  `WebApp.Event:${eventName}`,
  NAME_STRING_MAX,
  false // ellipsis is not allowed in service name
);

let config;

/** @type KatalMetrics.Publisher */
let instance;

/** @type KatalMetrics.Publisher */
let eventInstance;

/** @type AwsRum */
let awsRumClient;

export async function initMetrics() {
  config = await configService.get();

  try {
    initCloudWatchRUM(config);
  } catch (error) {
    // If rum fails to setup for any reason, catch it and do not pass it on.
    logger.debug('Failed to setup RUM', error);
    publishCounterMetric('InitRUMFailed', {
      additionalMetrics: {
        ErrorMessage: error.toString(),
        UserAgent: navigator.userAgent,
      },
    });
  }
}

export const ignoredErrorTypes = [
  ERROR_TYPES.Forbidden,
  ERROR_TYPES.NotAuthorizedInACL,
  ERROR_TYPES.Unauthorized,
  ERROR_TYPES.ClosedEvent,
  ERROR_TYPES.NoActiveLab,
];

/**
 * @param {ErrorEvent|PromiseRejectionEvent} errorEvent
 * @returns {boolean}
 */
export const shouldIgnoreRumError = errorEvent => {
  if (errorEvent instanceof PromiseRejectionEvent) return false;
  const ignoredExtensionSources = [
    'chrome-extension://',
    'moz-extension://',
    'webkit-masked-url://',
  ];
  const ignoredBrowserErrorPatterns = [
    /^ResizeObserver loop completed with undelivered notifications/i,
    /^ResizeObserver loop limit exceeded/i,
    /^Script error.$/i,
  ];
  const { filename, error, message } = errorEvent || {};
  if (
    !message ||
    ignoredBrowserErrorPatterns.some(pattern => pattern.test(message)) ||
    ignoredExtensionSources.some(src => filename?.includes(src)) ||
    ignoredExtensionSources.some(src => error?.stack?.includes(src))
  ) {
    return true;
  }
  return false;
};

/**
 * Filter out known issues that should not be logged as unexpected customer
 * impacting errors.
 * @param {any} errorMaybe An ErrorEvent, Error or primitive.
 * @returns {any}
 */
export const filterError = errorMaybe => {
  if (!has('errors', errorMaybe) || path(['errors', 'length'], errorMaybe) < 0)
    return errorMaybe;

  const filteredErrors = propOr([], 'errors', errorMaybe).reduce(
    (accErrors, err) => {
      if (includes(prop('errorType', err), ignoredErrorTypes)) return accErrors;
      return [...accErrors, err];
    },
    []
  );

  if (isEmpty(filteredErrors)) return null;
  return {
    ...errorMaybe,
    errors: filteredErrors,
  };
};

const initCloudWatchRUM = config => {
  const { identityPoolId, guestRoleArn, endpoint, applicationId, region } =
    prop('rum', config) || {};
  if (
    ![identityPoolId, guestRoleArn, endpoint, applicationId, region].every(
      Boolean
    )
  ) {
    logger.debug('Missing configuration to setup RUM', [
      identityPoolId,
      guestRoleArn,
      endpoint,
      applicationId,
      region,
    ]);
    return;
  }

  const isAllowedToUseSessionCookies = () =>
    hasShortbreadConsent(RUM_USER) && hasShortbreadConsent(RUM_SESSION);

  /** @type {import('aws-rum-web').AwsRumConfig} */
  const awsRumConfig = {
    identityPoolId,
    guestRoleArn,
    endpoint,
    telemetries: [
      'performance',
      [
        'errors',
        {
          stackTraceLength: 5000,
          ignore: shouldIgnoreRumError,
        },
      ],
      [
        'http',
        {
          // Setting `addXRayTraceIdHeader` specifically to false as we need to be very careful
          // with this flag. Refer to RUM documentation if curious to enable it in the future.
          // It adds a header to all requests which may or may not be supported by consumed APIs.
          // https://github.com/aws-observability/aws-rum-web/blob/main/docs/cdn_installation.md#http
          addXRayTraceIdHeader: false,
          recordAllRequests: true,
          stackTraceLength: 5000,
        },
      ],
      [
        // https://github.com/aws-observability/aws-rum-web/blob/main/docs/configuration.md#interaction
        'interaction',
        {
          enableMutationObserver: true,
          events: clickStreamSelectors,
        },
      ],
    ],
    allowCookies: isAllowedToUseSessionCookies(),
    enableXRay: true,
    disableAutoPageView: true, // Handle this manually to aggregate lab URLs.
    sessionSampleRate: 1, // Record all sessions.
    sessionEventLimit: 0, // No limit. This allows us to capture all event and not "max 200 events" which is the default.
    sessionLengthSeconds: 60 * 30, // Default = 60 * 30. Note, the session will renew automatically.
  };

  awsRumClient = new AwsRum(applicationId, '1.0.0', region, awsRumConfig);

  // Make sure we update cookie consent when customers update their preferences.
  Hub.listen(COOKIE_CONSENT_CHANGED, () => {
    if (!awsRumClient) return;
    awsRumClient.allowCookies(isAllowedToUseSessionCookies());
  });
};

/**
 * Track attribute which will be added to the metadata of all events in the RUM session.
 * @param {String} key Keys must conform to the following regex: `^(?!pageTags)(?!aws:)[a-zA-Z0-9_:]{1,128}$`
 * @param {String|Number|Boolean} value Values can have up to 256 characters
 * @param {*} [rumClient] (optional) Used for DI
 */
export const trackRumSessionAttribute = (
  key,
  value,
  rumClient = awsRumClient
) => {
  if (!rumClient) return;
  rumClient.addSessionAttributes({ [key]: value });
};

const createMetricsDriver = () => {
  const domain = prop('domain', config);
  const realm = prop('realm', config);

  // If config is not initialized use a no-op driver
  // Check this first to make it obvious during development if you forget to call initMetrics()
  if (!domain || !realm) {
    logger.debug('Use KatalMetricsDriverArrayCollector');
    const metricsDriver = new KatalMetricsDriverArrayCollector();
    //  Attach to global window object so tests can see it
    window.metricsDriver = metricsDriver;
    return metricsDriver;
  }

  // Publish metrics to logger for development
  if (isDevelopment && prop('consoleLogMetrics', config)) {
    logger.debug('Use KatalMetricsDriverConsoleLogJson');
    return new KatalMetricsDriverConsoleLogJson(logger);
  }

  // For non-development when config has been initialized publish metrics to sushi
  logger.debug('Use KatalMetricsDriverSushi');
  return new KatalMetricsDriverSushi.Builder()
    .withDomainRealm(domain, realm)
    .withErrorHandler(metricsErrorHandler)
    .build();
};

const metricsErrorHandler = isDevelopment ? logger.error : () => {};

const createInitialMetricsContext = () =>
  new KatalMetrics.Context.Builder()
    .withSite(SITE_NAME)
    .withServiceName(SERVICE_NAME)
    .build();

const createMetricsInstance = () =>
  new KatalMetrics.Publisher(
    createMetricsDriver(),
    metricsErrorHandler,
    createInitialMetricsContext()
  );

const createInitialEventMetricsContext = () =>
  new KatalMetrics.Context.Builder()
    .withSite(SITE_NAME)
    .withServiceName(eventServiceName)
    .build();

const createEventMetricsInstance = () =>
  new KatalMetrics.Publisher(
    createMetricsDriver(),
    metricsErrorHandler,
    createInitialEventMetricsContext()
  );

/**
 * Creates action publisher and publishes counter metric under the provided method name.
 * The metric is for the provided counter name, as well as string metrics for each key/value
 * pair in the optionMetricsData parameter. All of these are under the same unique actionID
 * so they can be related in the data warehouse.
 *
 * @param {String} methodName Method name for the action publisher.
 * @param {Object} [optionalParams] (optional)
 * @param {Object} [optionalParams.additionalMetrics] Remainder of the data to publish
 * @param {String} [optionalParams.counterName] Name of counter monitor; default is 'Impressions'
 * @param {Number} [optionalParams.counterValue] Counter value; default is 1
 */
export const publishCounterMetric = (
  methodName,
  { ...optionalParams } = {}
) => {
  const counterName = propOr(IMPRESSIONS, 'counterName', optionalParams);
  const counterValue = propOr(1, 'counterValue', optionalParams);
  const additionalMetrics = propOr({}, 'additionalMetrics', optionalParams);

  const publisher = metrics.createPublisher(methodName);
  publisher.publishCounter(counterName, counterValue);
  const publishString = (value, key) =>
    typeof value === 'number'
      ? publisher.publishCounter(key, value)
      : publisher.publishString(key, prepareStringMetric(value));
  forEachObjIndexed(publishString, additionalMetrics);
};

/**
 * Creates action publisher and publshes a counter metric for the item clicked,
 * as well as string metrics for each key/value pair in the optionMetricsData parameter.
 * All of these are under the same unique actionID so they can be related in data warehouse.
 *
 * @param {String} itemClicked Description of item clicked
 * @param {Object} [optionalMetricsData] (optional) Remainder of the data to publish, examples:
 *        destinationUrl - URL of destination page
 *        currentPath - URL of the current page
 *        clickLocation - Component/Page clicked on
 */
export const publishButtonClickMetric = (
  itemClicked,
  { ...optionalMetricsData } = {}
) => {
  const publisher = metrics.createPublisher('ButtonClick');
  publisher.publishCounter(itemClicked, 1);
  const publishString = (value, key) =>
    publisher.publishString(key, prepareStringMetric(value));
  forEachObjIndexed(publishString, optionalMetricsData);
};

/**
 * Wraps an async request and captures success/failure counter metrics as well
 * as a timer metric for the time spent.
 *
 * @example
 * // results in the following metrics published under methodName "GetPodium":
 * //  - GetPodiumSuccess/GetPodiumError (counter metric)
 * //  - GetPodiumTime (timer metric)
 * interceptRequestMetrics('GetPodium', getPodium());
 *
 * @template T
 * @param {String} namespace Metric namespace.
 * @param {Promise<T>} promise The result of the promise will get passed through.
 * @param {KatalMetrics.Publisher} [publisher] (optional) The metrics publisher to use.
 * @returns {Promise<T>}
 */
export const interceptRequestMetrics = (
  namespace,
  promise,
  publisher = undefined
) => {
  const metricsPublisher = publisher || metrics.createPublisher(namespace);
  const timerMetric = metrics
    .createTimerStopWatch(`${namespace}Time`)
    .withMonitor();

  return promise
    .then(data => {
      metricsPublisher.publishCounter(`${namespace}Success`, 1);
      return data;
    })
    .catch(response => {
      const errors = propOr([], 'errors', response);
      if (errors.find(propEq('errorType', ERROR_TYPES.Forbidden)))
        metricsPublisher.publishCounter(`${namespace}Forbidden`, 1);
      metricsPublisher.publishCounter(`${namespace}Error`, 1);
      throw response;
    })
    .finally(data => {
      metricsPublisher.publishMetric(timerMetric);
      return data;
    });
};

/**
 * Used to publish metrics which has an customer experience impact.
 *
 * @param {string} metricsNamespace
 * @param {string} error  An ErrorEvent, Error or primitive.
 * @param {object} [additionalMetrics]  An ErrorEvent, Error or primitive.
 */
export const publishFatal = (
  metricsNamespace,
  error,
  additionalMetrics = {}
) => {
  const filteredError = filterError(error);
  if (!filteredError) return;

  const errorData = {
    error: filteredError?.toString() || 'N/A',
    stack: filteredError?.stack || 'N/A',
    userAgent: navigator.userAgent,
  };

  publishCounterMetric(EH_FATAL_COUNTER, {
    additionalMetrics: {
      namespace: metricsNamespace,
      ...errorData,
      ...additionalMetrics,
    },
  });
  publishCounterMetric(metricsNamespace, {
    counterName: `${metricsNamespace}Fatal`,
    additionalMetrics: {
      ...errorData,
      ...additionalMetrics,
    },
  });
  metrics.recordRumError(`${metricsNamespace}Fatal`, filteredError);
};

// Note: Some adblockers may block metrics being posted from localhost. See: https://sage.amazon.com/posts/746798
const metrics = {
  /**
   * Initialize the metrics library.
   */
  initMetrics,

  /**
   * @returns {KatalMetrics.Publisher}
   */
  getInstance() {
    if (!instance) {
      instance = createMetricsInstance();
    }
    return instance;
  },

  /**
   * @returns {KatalMetrics.Publisher}
   */
  getEventInstance() {
    if (!eventInstance) {
      eventInstance = createEventMetricsInstance();
    }
    return eventInstance;
  },

  /**
   * @returns {Object<String, KatalMetrics.Publisher>}
   */
  getAllInstances() {
    return {
      instance: this.getInstance(),
      eventInstance: this.getEventInstance(),
    };
  },

  /**
   * @param {String} name Name of the timer
   * @param {Number} startTime ms epoch time to start from
   * @returns {TimerStopwatch}
   */
  createTimerStopWatch(...args) {
    return new KatalMetrics.Metric.TimerStopwatch(...args);
  },

  /**
   * @param {String} methodName
   * @returns {KatalMetrics.Publisher}
   */
  createPublisher(methodName) {
    const { instance, eventInstance } = this.getAllInstances();
    const publishers = [
      instance.newChildActionPublisherForMethod(methodName),
      eventInstance.newChildActionPublisherForMethod(methodName),
    ];

    return {
      publishString(name, value) {
        for (let publisher of publishers)
          publisher.publishStringTruncate(name, prepareStringMetric(value));
      },
      publishCounter(name, value) {
        for (let publisher of publishers)
          publisher.publishCounterMonitor(name, value);
      },
      publishTimer(name, value) {
        for (let publisher of publishers)
          publisher.publishTimerMonitor(name, value);
      },
      publishMetric(value) {
        for (let publisher of publishers) publisher.publish(value);
      },
    };
  },

  /**
   * @param {string} pageId The unique ID for the page within the application.
   */
  recordRumPageView(pageId) {
    if (!awsRumClient) return;
    awsRumClient.recordPageView(pageId);
  },

  /**
   * @param {string} context  An ErrorEvent, Error or primitive.
   * @param {any} errorMaybe  An ErrorEvent, Error or primitive.
   */
  recordRumError(context, errorMaybe) {
    if (!awsRumClient) return;
    const filteredError = filterError(errorMaybe);
    if (!filteredError) return;
    awsRumClient.recordError(createWrappedError(context, filteredError));
  },
};

export default metrics;
