import { is, prop, propOr, length } from 'ramda';
import { LAB_CONTEXT_VERSION } from '../constants/blueprint';
import './typedef';

export class EHBlueprint {
  constructor(payload) {
    /** @type {string} */
    this.arn = prop('arn', payload);
    /** @type {string} */
    this.type = prop('type', payload);
    /** @type {string} */
    this.availability = prop('availability', payload);
    /** @type {string} */
    this.connectivity = prop('connectivity', payload);
    /** @type {string} */
    this.createdAt = prop('createdAt', payload);
    /** @type {string} */
    this.duration = prop('duration', payload);
    /** @type {Locale[]} */
    this.locales = prop('locales', payload);
    /** @type {string[]]} */
    this.regions = prop('regions', payload);
    /** @type {string} */
    this.level = prop('level', payload);
    /** @type {string[]]} */
    this.services = propOr([], 'services', payload);
    /** @type {string[]]} */
    this.certifications = propOr([], 'certifications', payload);
    /** @type {string} */
    this.status = prop('status', payload);
    /** @type {LabContext} */
    this.labContext = prop('labContext', payload);
  }

  /**
   * The `dur` property is used for filtering.
   * @type {LabDurationId}
   */
  get dur() {
    return this.getDurationId();
  }

  /**
   * Parsed duration object with hour and minutes.
   * @type {Object|undefined}
   */
  get durationData() {
    const duration = this.duration;
    if (!duration) return undefined;
    // duration is given by Content Service in a predefined format: https://tiny.amazon.com/fqw9h3nm
    // The regex matches: "1h1m1s", "1m1s" or "1s"
    if (!/(^([0-9]+h)?([0-9]+m)?[0-9]+s$)/i.test(duration)) return undefined;

    const hours = duration.substring(0, duration.indexOf('h')) || '0';
    const minutes =
      duration.substring(duration.indexOf('h') + 1, duration.indexOf('m')) ||
      '0';

    return { hours, minutes };
  }

  /**
   * Parses duration string and returns it in minutes
   * @returns {number} duration in minutes
   */
  getDurationInMinutes() {
    if (!this.durationData) return 0;
    const { hours, minutes } = this.durationData;
    return parseInt(hours) * 60 + parseInt(minutes);
  }

  /**
   * Returns a short-form string for the blueprint duration.
   * @returns {LabDurationId}
   */
  getDurationId() {
    if (!this.durationData) return '';
    // Be sure to update the sort order in the catalog constants file if you add
    // or remove an entry from this function.
    let durationInMinutes = this.getDurationInMinutes(this.duration);
    if (durationInMinutes < 0) return '';
    if (durationInMinutes <= 60) return 'short';
    if (durationInMinutes < 120) return 'medium';
    return 'long';
  }

  /**
   * Checks if the blueprint is configured to run lab evaluations.
   * @returns {boolean}
   */
  hasLabEvaluation() {
    return (
      // Validating the expected version
      this.labContext?.version === LAB_CONTEXT_VERSION &&
      // Validating that there is (at least) one lab function for evaluation
      Boolean(this.labContext?.labEvaluation?.functionNames?.[0])
    );
  }

  /**
   * Checks if the blueprint has disabled lab eval submissions
   * @returns {boolean}
   */
  hasSubmissionDisabled() {
    const disableSubmission = this.labContext?.labEvaluation?.disableSubmission;
    return is(Boolean, disableSubmission) ? disableSubmission : false;
  }

  /**
   * Checks if the blueprint has a grading template and returns string grading template arn if found or empty string
   * @returns {string}
   */
  getGradingTemplateArn() {
    const gradingTemplate = this.labContext?.labEvaluation?.gradingTemplate;
    return is(String, gradingTemplate) && length(gradingTemplate)
      ? gradingTemplate
      : '';
  }
}
