import { defineMessages } from 'react-intl';

const messages = defineMessages({
  primaryMessage: {
    id: 'unauthorizedPage.primaryMessage',
    defaultMessage: 'Oh no, it looks like you do not have access.',
  },
  secondaryMessage: {
    id: 'unauthorizedPage.secondaryMessage',
    defaultMessage: 'Please contact your Learning Administrator.',
  },
  // SkillBuilder copy
  skillBuilderHeading: {
    id: 'unauthorizedPage.skillBuilderHeading',
    defaultMessage: 'It looks like you don’t have access',
  },
  skillBuilderSubscriptionInfo: {
    id: 'unauthorizedPage.skillBuilderSubscriptionInfo',
    defaultMessage:
      'You need a Skill Builder subscription to access this lab. <link>Learn more</link>',
  },
  skillBuilderESSOInfo: {
    id: 'unauthorizedPage.skillBuilderESSOInfo',
    defaultMessage:
      'If you belong to a company or partner organization, request a subscription from your learning administrator.',
  },
  skillBuilderHelpInfo: {
    id: 'unauthorizedPage.skillBuilderHelpInfo',
    defaultMessage:
      'Already a subscriber? You may be signed in with a different account. Sign out and sign in again with the correct account.',
  },
});

export default messages;
