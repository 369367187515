import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'labAssessment.header',
    defaultMessage: 'Post-assessment',
  },
  description: {
    id: 'labAssessment.description',
    defaultMessage:
      'This assessment is designed to gauge your understanding of the material covered.',
  },
  why: {
    id: 'labAssessment.why',
    defaultMessage:
      '<strong>Why this matters:</strong> Completing this assessment will help reinforce your knowledge and highlight any areas that may need further review.',
  },
  note: {
    id: 'labAssessment.note',
    defaultMessage:
      '<strong>Note:</strong> This assessment has a time limit. After this time, the assessment will automatically force quit.',
  },
  launch: {
    id: 'labAssessment.launch',
    defaultMessage: 'Launch post-assessment',
  },
  resume: {
    id: 'labAssessment.resume',
    defaultMessage: 'Resume post-assessment',
  },
  retake: {
    id: 'labAssessment.retake',
    defaultMessage: 'Retake post-assessment',
  },
  passed: {
    id: 'labAssessment.passed',
    defaultMessage: 'Passed',
  },
  failed: {
    id: 'labAssessment.failed',
    defaultMessage: 'Failed',
  },
  errorDefault: {
    id: 'labAssessment.errorDefault',
    defaultMessage:
      'Uh oh, something went wrong. Please reload the page and try again.',
  },
  errorConflict: {
    id: 'labAssessment.errorConflict',
    defaultMessage: 'Post-assessment cannot be started in the current state.',
  },
  reload: {
    id: 'labAssessment.reload',
    defaultMessage: 'Reload page',
  },
});

export default messages;
