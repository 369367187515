import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'ramda';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';

import { getLocaleOrAvailable } from '../../utils/localeUtils';
import FEATURES from '../../constants/features';
import { useEventContext, useLocaleContext } from '../../contexts';
import { EHCustomCallouts } from '../../models/EHCustomCallouts';
import logger from '../../utils/logger';
import { CALLOUT_TYPES, SLOTS } from './constants';
import {
  CalloutAlert,
  CalloutBanner,
  CalloutContainer,
  CalloutErrorBoundary,
  CalloutHeroBanner,
  CalloutMarkdown,
  CalloutTopic,
} from './';

/**
 * @example
 * // Normal use
 * <CustomCallouts slot="example" />
 * // Mechanism to filter out specific named components, if configured in the callout `name` param
 * <CustomCallouts slot="example" filterName={"spl-88"} />
 *
 * @param {string} props.slot Select what slot to render
 * @param {string} [props.filterName] Components that has a name will only get shown if it matches the passed in prop
 * @returns {JSX.Element}
 */
const CustomCallouts = ({ slot, filterName }) => {
  const [userLocale] = useLocaleContext();
  const { event } = useEventContext();

  if (!event.hasFeature(FEATURES.customCallouts)) return null;

  const customCallouts = new EHCustomCallouts(
    event.getMetadata(FEATURES.customCallouts)
  );

  // Get components for the given slot
  const calloutComponents = customCallouts[slot] || [];
  // Some components should only be shown when the filterName matches, ex a specific SPL
  const filteredComponents = calloutComponents.filter(c => {
    if (!c.name || !filterName) return true;
    else return c.name?.toLowerCase() === filterName?.toLowerCase();
  });
  if (isEmpty(filteredComponents)) return null;

  return (
    <SpaceBetween size="s">
      {filteredComponents.map((callout, idx) => {
        if (isNil(callout.locales) || isEmpty(callout.locales)) return null;

        // Get localized callout component parameters, unique structure per component.
        const calloutParams = getLocaleOrAvailable(userLocale, callout);
        if (!calloutParams) return null;

        let component;
        switch (callout.type) {
          case CALLOUT_TYPES.alert:
            component = <CalloutAlert params={calloutParams} />;
            break;
          case CALLOUT_TYPES.banner:
            component = <CalloutBanner params={calloutParams} />;
            break;
          case CALLOUT_TYPES.markdown:
            component = <CalloutMarkdown params={calloutParams} />;
            break;
          case CALLOUT_TYPES.hero:
            component = <CalloutHeroBanner params={calloutParams} />;
            break;
          case CALLOUT_TYPES.container:
            component = <CalloutContainer params={calloutParams} />;
            break;
          case CALLOUT_TYPES.topic:
            component = <CalloutTopic params={calloutParams} />;
            break;
          default:
            logger.debug(
              `Unsupported CustomCallout type: ${callout.type}`,
              callout
            );
            return null;
        }

        if (!component) return null;
        return (
          <CalloutErrorBoundary key={idx}>{component}</CalloutErrorBoundary>
        );
      })}
    </SpaceBetween>
  );
};

CustomCallouts.propTypes = {
  blueprint: PropTypes.object,
  slot: PropTypes.oneOf(Object.keys(SLOTS)).isRequired,
};

export default CustomCallouts;
