const FEATURES = {
  catalogFiltering: 'CatalogFiltering',
  catalogSearch: 'CatalogSearch',
  catalogPagination: 'CatalogPagination',
  connectChat: 'Connect',
  feedbackOpensExternalLink: 'FeedbackOpensExternalLink',
  inPersonSupport: 'InPersonSupport',
  labEvaluation: 'LabEvaluation',
  budgetControl: 'BudgetControl',
  customCallouts: 'CustomCallouts',
  labExpiryTime: 'LabPageExpirationTime',
  blueprintAIWidget: 'BlueprintAIWidget',
  labAssessment: 'LabAssessment',
};

export default FEATURES;
