import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'skillBuilder.header',
    defaultMessage: 'Continue building after re:Invent',
  },
  description: {
    id: 'skillBuilder.description',
    defaultMessage:
      'Dive deeper to advance your professional goals with access to 150+ self-paced labs and more, with an AWS Skill Builder subscription.',
  },
  warning: {
    id: 'skillBuilder.warning',
    defaultMessage:
      'If you have a lab running, please finish it before signing in to Skill Builder.',
  },
  button: {
    id: 'skillBuilder.button',
    defaultMessage: 'Learn more',
  },
  modal: {
    id: 'skillBuilder.modal',
    defaultMessage:
      'Redeem your free trial at the Skill Builder sign up station located in The Venetian (L2, Titian 2306) and Mandalay Bay (South L2, Mandalay Bay H).',
  },
  closeAriaLabel: {
    id: 'skillBuilder.closeAriaLabel',
    defaultMessage: 'Close modal',
  },
});

export default messages;
