import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import { useQuery } from 'react-query';
import { graphql } from '../utils/graphQLService';
import { interceptRequestMetrics, publishFatal } from '../utils/metrics';
import { EHEvent } from '../models';
import { isOnline } from '../utils/envUtil';
import { useAuthContext } from '.';
import {
  calculateRetryDelay,
  isNonRetryableError,
} from '../utils/promiseUtils';

const getEventQuery = `
  query GetEvent {
    getEvent {
      announcements {
        locales {
          text
          locale
        }
      }
      arn
      capLabsPerUser {
        capAt
        timeWindowHrs
      }
      channels
      collectionArn
      concurrentLabsLimit
      defaultLocale 
      defaultTheme
      description
      endTime
      eventLink {
        url
        locales {
          locale
          text
        }
      }
      features {
        name
        enabled
        metadata
      }
      logoAltText
      name
      redirects {
        name
        location
      }
      spotlightArn
      startTime
      supportLinkUrl
    }
  }
`;

/**
 * @typedef EventContext
 * @property {?EHEvent} event
 * @property {?Error} error
 * @property {boolean} isLoading
 * @property {boolean} isError
 */

/** @type {import('react').Context<EventContext>} */
export const EventContext = createContext();

export const useEventContext = () => useContext(EventContext);

const FIVE_MIN_IN_MS = 5 * 60 * 1000;

const metricsNamespace = 'GetEvent';

/**
 * @param {Number} failureCount
 * @param {any} error
 */
const shouldRetry = (failureCount, error) => {
  if (isNonRetryableError(error)) return false;
  return failureCount < 3;
};

const EventContextProvider = ({ children, eventData = undefined }) => {
  const [{ isAuthenticated }] = useAuthContext();
  const { data, isLoading, isError, error } = useQuery({
    queryKey: 'getEvent',
    queryFn: () =>
      interceptRequestMetrics(metricsNamespace, graphql(getEventQuery)),
    enabled: isAuthenticated && isOnline(),
    staleTime: FIVE_MIN_IN_MS,
    // initialData can be used for many things, here it is used to prepopulate data when testing.
    initialData: eventData && { data: { getEvent: { ...eventData } } },
    retry: shouldRetry,
    retryDelay: attempt => calculateRetryDelay(attempt, { interval: 200 }),
    onError: error => {
      publishFatal(metricsNamespace, error);
    },
  });
  const event = new EHEvent(pathOr({}, ['data', 'getEvent'], data));

  return (
    <EventContext.Provider
      value={{
        event,
        isLoading,
        isError,
        error,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

EventContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  eventData: PropTypes.object,
};

export default EventContextProvider;
