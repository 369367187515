export const CALLOUT_TYPES = {
  alert: 'alert',
  banner: 'banner',
  markdown: 'markdown',
  hero: 'hero',
  container: 'container',
  topic: 'topic',
};

export const SLOTS = {
  labs: 'labs',
  labsBottom: 'labsBottom',
  homeHero: 'homeHero',
  homeTop: 'homeTop',
  homeBottom: 'homeBottom',
  catalogTop: 'catalogTop',
  catalogBottom: 'catalogBottom',
};

export const CALLOUT_MEDIA = {
  awsJamGang: 'callouts:aws-jam-gang',
};
