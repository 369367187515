import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Box from '@amzn/awsui-components-react/polaris/box';
import Popover from '@amzn/awsui-components-react/polaris/popover';
import Icon from '@amzn/awsui-components-react/polaris/icon';
import Button from '@amzn/awsui-components-react/polaris/button';

import messages from './Footer.messages';
import useWindowSize from '../../hooks/useWindowSize';

import './FooterLayout.scss';

const FooterContent = ({ left, right, mobileItems, mobileCollapsed }) => {
  const { formatMessage } = useIntl();
  const { isMobile } = useWindowSize();

  if (isMobile) {
    return (
      <div className="Footer__mobile-container">
        <div>{mobileItems}</div>
        <div>
          <Popover
            fixedWidth
            size="large"
            content={
              <div className="Footer__mobile-content">{mobileCollapsed}</div>
            }
            position="top"
            triggerType="custom"
            dismissAriaLabel={formatMessage(messages.dismissAriaLabel)}
          >
            <Button variant="inline-link" className="Footer__mobile-btn">
              {formatMessage(messages.mobileMenuTitle)}
              <Icon name="ellipsis" />
            </Button>
          </Popover>
        </div>
      </div>
    );
  }
  return (
    <>
      <Box margin={{ left: 'l' }} className="Footer__left">
        {left}
      </Box>
      <Box margin={{ right: 'l' }} className="Footer__right">
        {right}
      </Box>
    </>
  );
};

const FooterLayout = ({
  left,
  right,
  mobileItems,
  mobileCollapsed,
  testid,
}) => {
  return (
    <footer className="Footer" role="contentinfo" data-testid={testid}>
      <FooterContent
        left={left}
        right={right}
        mobileItems={mobileItems}
        mobileCollapsed={mobileCollapsed}
      />
    </footer>
  );
};

FooterLayout.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
  mobileItems: PropTypes.node,
  mobileCollapsed: PropTypes.node,
  testid: PropTypes.string,
};

export default FooterLayout;
