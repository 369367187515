import { defineMessages } from 'react-intl';

const messages = defineMessages({
  a4b: {
    id: 'services.a4b.short',
    defaultMessage: 'Alexa for Business',
  },
  apigateway: {
    id: 'services.apigateway.short',
    defaultMessage: 'API Gateway',
  },
  cloudformation: {
    id: 'services.cloudformation.short',
    defaultMessage: 'AWS CloudFormation',
  },
  cloudfront: {
    id: 'services.cloudfront.short',
    defaultMessage: 'CloudFront',
  },
  cloudsearch: {
    id: 'services.cloudsearch.short',
    defaultMessage: 'CloudSearch',
  },
  cloudtrail: {
    id: 'services.cloudtrail.short',
    defaultMessage: 'CloudTrail',
  },
  cloudwatch: {
    id: 'services.cloudwatch.short',
    defaultMessage: 'CloudWatch',
  },
  codecommit: {
    id: 'services.codecommit.short',
    defaultMessage: 'CodeCommit',
  },
  codedeploy: {
    id: 'services.codedeploy.short',
    defaultMessage: 'CodeDeploy',
  },
  config: {
    id: 'services.config.short',
    defaultMessage: 'AWS Config',
  },
  devicefarm: {
    id: 'services.devicefarm.short',
    defaultMessage: 'Device Farm',
  },
  dms: {
    id: 'services.dms.short',
    defaultMessage: 'DMS',
  },
  dynamodb: {
    id: 'services.dynamodb.short',
    defaultMessage: 'DynamoDB',
  },
  ebs: {
    id: 'services.ebs.short',
    defaultMessage: 'EBS',
  },
  ec2: {
    id: 'services.ec2.short',
    defaultMessage: 'EC2',
  },
  ecr: {
    id: 'services.ecr.short',
    defaultMessage: 'ECR',
  },
  ecs: {
    id: 'services.ecs.short',
    defaultMessage: 'ECS',
  },
  elasticache: {
    id: 'services.elasticache.short',
    defaultMessage: 'ElastiCache',
  },
  elasticbeanstalk: {
    id: 'services.elasticbeanstalk.short',
    defaultMessage: 'Elastic Beanstalk',
  },
  elasticfilesystem: {
    id: 'services.elasticfilesystem.short',
    defaultMessage: 'EFS',
  },
  elasticloadbalancing: {
    id: 'services.elasticloadbalancing.short',
    defaultMessage: 'ELB',
  },
  elasticmapreduce: {
    id: 'services.elasticmapreduce.short',
    defaultMessage: 'Amazon EMR',
  },
  elastictranscoder: {
    id: 'services.elastictranscoder.short',
    defaultMessage: 'Elastic Transcoder',
  },
  es: {
    id: 'services.es.short',
    defaultMessage: 'Amazon ES',
  },
  iam: {
    id: 'services.iam.short',
    defaultMessage: 'IAM',
  },
  inspector: {
    id: 'services.inspector.short',
    defaultMessage: 'Amazon Inspector',
  },
  iot: {
    id: 'services.iot.short',
    defaultMessage: 'AWS IoT',
  },
  kinesis: {
    id: 'services.kinesis.short',
    defaultMessage: 'Kinesis',
  },
  kms: {
    id: 'services.kms.short',
    defaultMessage: 'AWS KMS',
  },
  lambda: {
    id: 'services.lambda.short',
    defaultMessage: 'Lambda',
  },
  lex: {
    id: 'services.lex.short',
    defaultMessage: 'Amazon Lex',
  },
  lightsail: {
    id: 'services.lightsail.short',
    defaultMessage: 'Lightsail',
  },
  mediaconvert: {
    id: 'services.mediaconvert.short',
    defaultMessage: 'MediaConvert',
  },
  medialive: {
    id: 'services.medialive.short',
    defaultMessage: 'MediaLive',
  },
  mediapackage: {
    id: 'services.mediapackage.short',
    defaultMessage: 'MediaPackage',
  },
  mediastore: {
    id: 'services.mediastore.short',
    defaultMessage: 'MediaStore',
  },
  mediatailor: {
    id: 'services.mediatailor.short',
    defaultMessage: 'MediaTailor',
  },
  opsworks: {
    id: 'services.opsworks.short',
    defaultMessage: 'OpsWorks',
  },
  polly: {
    id: 'services.polly.short',
    defaultMessage: 'Amazon Polly',
  },
  rds: {
    id: 'services.rds.short',
    defaultMessage: 'RDS',
  },
  redshift: {
    id: 'services.redshift.short',
    defaultMessage: 'Amazon Redshift',
  },
  rekognition: {
    id: 'services.rekognition.short',
    defaultMessage: 'Amazon Rekognition',
  },
  route53: {
    id: 'services.route53.short',
    defaultMessage: 'Route 53',
  },
  s3: {
    id: 'services.s3.short',
    defaultMessage: 'S3',
  },
  sagemaker: {
    id: 'services.sagemaker.short',
    defaultMessage: 'SageMaker',
  },
  sns: {
    id: 'services.sns.short',
    defaultMessage: 'SNS',
  },
  sqs: {
    id: 'services.sqs.short',
    defaultMessage: 'SQS',
  },
  storagegateway: {
    id: 'services.storagegateway.short',
    defaultMessage: 'Storage Gateway',
  },
  sts: {
    id: 'services.sts.short',
    defaultMessage: 'AWS STS',
  },
  transcribe: {
    id: 'services.transcribe.short',
    defaultMessage: 'Amazon Transcribe',
  },
  translate: {
    id: 'services.translate.short',
    defaultMessage: 'Amazon Translate',
  },
  trustedadvisor: {
    id: 'services.trustedadvisor.short',
    defaultMessage: 'Trusted Advisor',
  },
  vpc: {
    id: 'services.vpc.short',
    defaultMessage: 'Amazon VPC',
  },
  'access-analyzer': {
    id: 'services.access-analyzer.short',
    defaultMessage: 'IAM Access Analyzer',
  },
  acm: {
    id: 'services.acm.short',
    defaultMessage: 'ACM',
  },
  amplify: {
    id: 'services.amplify.short',
    defaultMessage: 'Amplify',
  },
  'application-autoscaling': {
    id: 'services.application-autoscaling.short',
    defaultMessage: 'Application Auto Scaling',
  },
  appmesh: {
    id: 'services.appmesh.short',
    defaultMessage: 'App Mesh',
  },
  appsync: {
    id: 'services.appsync.short',
    defaultMessage: 'AWS AppSync',
  },
  arsenal: {
    id: 'services.arsenal.short',
    defaultMessage: 'Arsenal',
  },
  athena: {
    id: 'services.athena.short',
    defaultMessage: 'Athena',
  },
  autoscaling: {
    id: 'services.autoscaling.short',
    defaultMessage: 'Amazon EC2 Auto Scaling',
  },
  'autoscaling-plans': {
    id: 'services.autoscaling-plans.short',
    defaultMessage: 'AWS Auto Scaling',
  },
  'aws-marketplace': {
    id: 'services.aws-marketplace.short',
    defaultMessage: 'AWS Marketplace',
  },
  backup: {
    id: 'services.backup.short',
    defaultMessage: 'AWS Backup',
  },
  'backup-storage': {
    id: 'services.backup-storage.short',
    defaultMessage: 'Backup Storage',
  },
  batch: {
    id: 'services.batch.short',
    defaultMessage: 'AWS Batch',
  },
  braket: {
    id: 'services.braket.short',
    defaultMessage: 'Braket',
  },
  cloud9: {
    id: 'services.cloud9.short',
    defaultMessage: 'AWS Cloud9',
  },
  codeartifact: {
    id: 'services.codeartifact.short',
    defaultMessage: 'CodeArtifact',
  },
  codebuild: {
    id: 'services.codebuild.short',
    defaultMessage: 'CodeBuild',
  },
  'codeguru-reviewer': {
    id: 'services.codeguru-reviewer.short',
    defaultMessage: 'CodeGuru Reviewer',
  },
  codepipeline: {
    id: 'services.codepipeline.short',
    defaultMessage: 'CodePipeline',
  },
  codestar: {
    id: 'services.codestar.short',
    defaultMessage: 'AWS CodeStar',
  },
  'codestar-connections': {
    id: 'services.codestar-connections.short',
    defaultMessage: 'CodeStar Connections',
  },
  'cognito-identity': {
    id: 'services.cognito-identity.short',
    defaultMessage: 'Cognito Identity',
  },
  'cognito-idp': {
    id: 'services.cognito-idp.short',
    defaultMessage: 'Cognito User Pools',
  },
  'cognito-sync': {
    id: 'services.cognito-sync.short',
    defaultMessage: 'Cognito Sync',
  },
  comprehend: {
    id: 'services.comprehend.short',
    defaultMessage: 'Amazon Comprehend',
  },
  comprehendmedical: {
    id: 'services.comprehendmedical.short',
    defaultMessage: 'Comprehend Medical',
  },
  'compute-optimizer': {
    id: 'services.compute-optimizer.short',
    defaultMessage: 'Compute Optimizer',
  },
  datapipeline: {
    id: 'services.datapipeline.short',
    defaultMessage: 'Data Pipeline',
  },
  datasync: {
    id: 'services.datasync.short',
    defaultMessage: 'DataSync',
  },
  dax: {
    id: 'services.dax.short',
    defaultMessage: 'Dax',
  },
  deepracer: {
    id: 'services.deepracer.short',
    defaultMessage: 'AWS DeepRacer',
  },
  discovery: {
    id: 'services.discovery.short',
    defaultMessage: 'Application Discovery Service',
  },
  ds: {
    id: 'services.ds.short',
    defaultMessage: 'Directory Service',
  },
  'ec2-instance-connect': {
    id: 'services.ec2-instance-connect.short',
    defaultMessage: 'EC2 Instance Connect',
  },
  ec2messages: {
    id: 'services.ec2messages.short',
    defaultMessage: 'Message Delivery Service',
  },
  eks: {
    id: 'services.eks.short',
    defaultMessage: 'Amazon EKS',
  },
  'emr-containers': {
    id: 'services.emr-containers.short',
    defaultMessage: 'Amazon EMR',
  },
  events: {
    id: 'services.events.short',
    defaultMessage: 'EventBridge',
  },
  'execute-api': {
    id: 'services.execute-api.short',
    defaultMessage: 'API Gateway',
  },
  firehose: {
    id: 'services.firehose.short',
    defaultMessage: 'Kinesis Data Firehose',
  },
  fis: {
    id: 'services.fis.short',
    defaultMessage: 'Fault Injection Simulator',
  },
  glacier: {
    id: 'services.glacier.short',
    defaultMessage: 'S3 Glacier',
  },
  glue: {
    id: 'services.glue.short',
    defaultMessage: 'AWS Glue',
  },
  greengrass: {
    id: 'services.greengrass.short',
    defaultMessage: 'AWS IoT Greengrass V2',
  },
  groundtruthlabeling: {
    id: 'services.groundtruthlabeling.short',
    defaultMessage: 'GroundTruth Labeling',
  },
  health: {
    id: 'services.health.short',
    defaultMessage: 'AWS Health',
  },
  healthlake: {
    id: 'services.healthlake.short',
    defaultMessage: 'HealthLake',
  },
  inspector2: {
    id: 'services.inspector2.short',
    defaultMessage: 'Inspector2',
  },
  iotanalytics: {
    id: 'services.iotanalytics.short',
    defaultMessage: 'AWS IoT Analytics',
  },
  kafka: {
    id: 'services.kafka.short',
    defaultMessage: 'Amazon MSK',
  },
  'kafka-cluster': {
    id: 'services.kafka-cluster.short',
    defaultMessage: 'Kafka Clusters',
  },
  kafkaconnect: {
    id: 'services.kafkaconnect.short',
    defaultMessage: 'Kafka Connect',
  },
  kinesisanalytics: {
    id: 'services.kinesisanalytics.short',
    defaultMessage: 'Kinesis Data Analytics',
  },
  kinesisvideo: {
    id: 'services.kinesisvideo.short',
    defaultMessage: 'Kinesis Video Streams',
  },
  lakeformation: {
    id: 'services.lakeformation.short',
    defaultMessage: 'Lake Formation',
  },
  'license-manager': {
    id: 'services.license-manager.short',
    defaultMessage: 'License Manager',
  },
  logs: {
    id: 'services.logs.short',
    defaultMessage: 'CloudWatch Logs',
  },
  m2: {
    id: 'services.m2.short',
    defaultMessage: 'AWS Mainframe Modernization',
  },
  machinelearning: {
    id: 'services.machinelearning.short',
    defaultMessage: 'Amazon ML',
  },
  macie2: {
    id: 'services.macie2.short',
    defaultMessage: 'Macie',
  },
  managedblockchain: {
    id: 'services.managedblockchain.short',
    defaultMessage: 'Managed Blockchain',
  },
  mgh: {
    id: 'services.mgh.short',
    defaultMessage: 'Migration Hub',
  },
  mgn: {
    id: 'services.mgn.short',
    defaultMessage: 'Application Migration Service',
  },
  mobiletargeting: {
    id: 'services.mobiletargeting.short',
    defaultMessage: 'Pinpoint',
  },
  'network-firewall': {
    id: 'services.network-firewall.short',
    defaultMessage: 'Network Firewall',
  },
  networkmanager: {
    id: 'services.networkmanager.short',
    defaultMessage: 'Network Manager',
  },
  pi: {
    id: 'services.pi.short',
    defaultMessage: 'Performance Insights',
  },
  pricing: {
    id: 'services.pricing.short',
    defaultMessage: 'Price List',
  },
  quicksight: {
    id: 'services.quicksight.short',
    defaultMessage: 'QuickSight',
  },
  ram: {
    id: 'services.ram.short',
    defaultMessage: 'AWS RAM',
  },
  'rds-data': {
    id: 'services.rds-data.short',
    defaultMessage: 'RDS Data API',
  },
  'rds-db': {
    id: 'services.rds-db.short',
    defaultMessage: 'RDS IAM Authentication',
  },
  'redshift-data': {
    id: 'services.redshift-data.short',
    defaultMessage: 'Redshift Data',
  },
  'redshift-serverless': {
    id: 'services.redshift-serverless.short',
    defaultMessage: 'Redshift Serverless',
  },
  'refactor-spaces': {
    id: 'services.refactor-spaces.short',
    defaultMessage: 'Refactor Spaces',
  },
  'resource-explorer': {
    id: 'services.resource-explorer.short',
    defaultMessage: 'Tag Editor',
  },
  'resource-groups': {
    id: 'services.resource-groups.short',
    defaultMessage: 'Resource Groups',
  },
  'route53-recovery-control-config': {
    id: 'services.route53-recovery-control-config.short',
    defaultMessage: 'Route 53 Recovery Controls',
  },
  'route53-recovery-readiness': {
    id: 'services.route53-recovery-readiness.short',
    defaultMessage: 'Route 53 Recovery Readiness',
  },
  route53domains: {
    id: 'services.route53domains.short',
    defaultMessage: 'Route 53 Domains',
  },
  route53resolver: {
    id: 'services.route53resolver.short',
    defaultMessage: 'Route 53 Resolver',
  },
  's3-object-lambda': {
    id: 'services.s3-object-lambda.short',
    defaultMessage: 'S3 Object Lambda',
  },
  schemas: {
    id: 'services.schemas.short',
    defaultMessage: 'EventBridge Schemas',
  },
  sdb: {
    id: 'services.sdb.short',
    defaultMessage: 'SimpleDB',
  },
  secretsmanager: {
    id: 'services.secretsmanager.short',
    defaultMessage: 'Secrets Manager',
  },
  servicecatalog: {
    id: 'services.servicecatalog.short',
    defaultMessage: 'AWS Service Catalog',
  },
  servicediscovery: {
    id: 'services.servicediscovery.short',
    defaultMessage: 'AWS Cloud Map',
  },
  servicequotas: {
    id: 'services.servicequotas.short',
    defaultMessage: 'Service Quotas',
  },
  ses: {
    id: 'services.ses.short',
    defaultMessage: 'Pinpoint Email Service',
  },
  shield: {
    id: 'services.shield.short',
    defaultMessage: 'Shield',
  },
  'sms-voice': {
    id: 'services.sms-voice.short',
    defaultMessage: 'Pinpoint SMS Voice',
  },
  sqlworkbench: {
    id: 'services.sqlworkbench.short',
    defaultMessage: 'SQL Workbench',
  },
  ssm: {
    id: 'services.ssm.short',
    defaultMessage: 'Systems Manager',
  },
  'ssm-guiconnect': {
    id: 'services.ssm-guiconnect.short',
    defaultMessage: 'Systems Manager GUI Connect',
  },
  ssmmessages: {
    id: 'services.ssmmessages.short',
    defaultMessage: 'Session Manager Message Gateway Service',
  },
  states: {
    id: 'services.states.short',
    defaultMessage: 'Step Functions',
  },
  support: {
    id: 'services.support.short',
    defaultMessage: 'Support',
  },
  synthetics: {
    id: 'services.synthetics.short',
    defaultMessage: 'CloudWatch Synthetics',
  },
  tag: {
    id: 'services.tag.short',
    defaultMessage: 'Resource Group Tagging API',
  },
  textract: {
    id: 'services.textract.short',
    defaultMessage: 'Amazon Textract',
  },
  tiros: {
    id: 'services.tiros.short',
    defaultMessage: 'Tiros',
  },
  waf: {
    id: 'services.waf.short',
    defaultMessage: 'AWS WAF',
  },
  'waf-regional': {
    id: 'services.waf-regional.short',
    defaultMessage: 'WAF Regional',
  },
  wafv2: {
    id: 'services.wafv2.short',
    defaultMessage: 'WAF V2',
  },
  xray: {
    id: 'services.xray.short',
    defaultMessage: 'X-Ray',
  },
  wellarchitected: {
    id: 'services.wellarchitected.short',
    defaultMessage: 'AWS WA',
  },
  serverlessrepo: {
    id: 'services.serverlessrepo.short',
    defaultMessage: 'AWS Serverless Application Repository',
  },
  personalize: {
    id: 'services.personalize.short',
    defaultMessage: 'Amazon Personalize',
  },
  memorydb: {
    id: 'services.memorydb.short',
    defaultMessage: 'MemoryDB',
  },
  gamelift: {
    id: 'services.gamelift.short',
    defaultMessage: 'GameLift',
  },
  docdbelastic: {
    id: 'services.docdbelastic.short',
    defaultMessage: 'Amazon DocumentDB',
  },
  acmpca: {
    id: 'services.acmpca.short',
    defaultMessage: 'AWS Private CA',
  },
  apprunner: {
    id: 'services.apprunner.short',
    defaultMessage: 'App Runner',
  },
  fsx: {
    id: 'services.fsx.short',
    defaultMessage: 'Amazon FSx',
  },
  rolesanywhere: {
    id: 'services.rolesanywhere.short',
    defaultMessage: 'IAM Roles Anywhere',
  },
  securityhub: {
    id: 'services.securityhub.short',
    defaultMessage: 'Security Hub',
  },
  codegurusecurity: {
    id: 'services.codegurusecurity.short',
    defaultMessage: 'CodeGuru Security',
  },
  codewhisperer: {
    id: 'services.codewhisperer.short',
    defaultMessage: 'CodeWhisperer',
  },
  drs: {
    id: 'services.drs.short',
    defaultMessage: 'AWS DRS',
  },
  evidently: {
    id: 'services.evidently.short',
    defaultMessage: 'Evidently',
  },
  geo: {
    id: 'services.geo.short',
    defaultMessage: 'Amazon Location',
  },
  globalaccelerator: {
    id: 'services.globalaccelerator.short',
    defaultMessage: 'Global Accelerator',
  },
  grafana: {
    id: 'services.grafana.short',
    defaultMessage: 'Amazon Managed Grafana',
  },
  internetmonitor: {
    id: 'services.internetmonitor.short',
    defaultMessage: 'Internet Monitor',
  },
  launchwizard: {
    id: 'services.launchwizard.short',
    defaultMessage: 'Launch Wizard',
  },
  omics: {
    id: 'services.omics.short',
    defaultMessage: 'Omics',
  },
  appstream: {
    id: 'services.appstream.short',
    defaultMessage: 'Amazon AppStream',
  },
  mediaconnect: {
    id: 'services.mediaconnect.short',
    defaultMessage: 'MediaConnect',
  },
  timestream: {
    id: 'services.timestream.short',
    defaultMessage: 'Timestream',
  },
  // ===============================================================
  // Added 2023-11-10
  // https://w.amazon.com/bin/viewrev/Hulkydew/ACS/Service_Support/WebHome?rev=84.1
  forecast: {
    id: 'services.forecast.short',
    defaultMessage: 'Forecast',
  },
  iotfleetwise: {
    id: 'services.iotfleetwise.short',
    defaultMessage: 'IoT FleetWise',
  },
  iotsitewise: {
    id: 'services.iotsitewise.short',
    defaultMessage: 'IoT SiteWise',
  },
  kendra: {
    id: 'services.kendra.short',
    defaultMessage: 'Amazon Kendra',
  },
  lookoutequipment: {
    id: 'services.lookoutequipment.short',
    defaultMessage: 'Lookout for Equipment',
  },
  lookoutvision: {
    id: 'services.lookoutvision.short',
    defaultMessage: 'Lookout for Vision',
  },
  pipes: {
    id: 'services.pipes.short',
    defaultMessage: 'EventBridge Pipes',
  },
  rum: {
    id: 'services.rum.short',
    defaultMessage: 'CloudWatch RUM',
  },
  'ssm-incidents': {
    id: 'services.ssm-incidents.short',
    defaultMessage: 'Incident Manager',
  },
  'vpc-lattice': {
    id: 'services.vpc-lattice.short',
    defaultMessage: 'VPC Lattice',
  },
  // ===============================================================
  // Added 2023-02-13
  // https://t.corp.amazon.com/D118934309
  q: {
    id: 'services.q.short',
    defaultMessage: 'Amazon Q',
  },
  'application-transformation': {
    id: 'services.application-transformation.short',
    defaultMessage: 'AWS Application Transformation Service',
  },
  iottwinmaker: {
    id: 'services.iottwinmaker.short',
    defaultMessage: 'AWS IoT TwinMaker',
  },
  serviceextract: {
    id: 'services.serviceextract.short',
    defaultMessage: 'AWS Microservice Extractor',
  },
  // ===============================================================
  // Added 2024-06-24
  bedrock: {
    id: 'services.bedrock.short',
    defaultMessage: 'Amazon Bedrock',
  },
  iotevents: {
    id: 'services.iotevents.short',
    defaultMessage: 'IoT Events',
  },
    // ===============================================================
});

export default messages;
