import { allPass, anyPass } from 'ramda';

import panelMessages from '../components/Catalog/CatalogFilterPanel.messages';
import serviceMessages from '../components/Catalog/services.messages';
import certificationMessages from '../components/Catalog/certifications.messages';
import levelMessages from '../components/Catalog/levels.messages';
import durationMessages from '../components/Catalog/duration.messages';

/**
 * Blueprint fields to search against
 * @constant {List}
 */
export const SEARCH_KEYS = [
  { name: 'locales.title', weight: 1 },
  { name: 'services', weight: 0.5 },
];

/**
 * Query parameter for search text
 */
export const SEARCH_QUERY_PARAM = 'search';
/**
 * Order in which the duration filter options will appear.
 * @constant {List}
 * Be sure to update getDurationId in the catalog utils file if you want to
 * add an entry to this list.
 */
export const durationSortOrder = ['short', 'medium', 'long'];
export const filterJoinType = allPass;
/**
 * Definitions for each field that the catalog can be filtered on. When adding
 * a new field to filter on, as long as a section is added to this constant with
 * the required fields, it will automatically be picked up. The filter name should
 * match the blueprint field that it is filtering against.
 */
export const filterDefinitions = {
  level: {
    title: panelMessages.levels,
    hasFilterData: blueprint => levelMessages[blueprint.level],
    appendFilterData: (accLevels, blueprint) =>
      filterDefinitions.level.hasFilterData(blueprint)
        ? [...accLevels, blueprint.level]
        : accLevels,
    getLabel: sku => levelMessages[sku],
    sortCompare: (a, b) => a.id - b.id,
    expandedByDefault: true,
    joinType: anyPass,
  },
  dur: {
    title: panelMessages.duration,
    hasFilterData: blueprint => durationMessages[blueprint.dur],
    appendFilterData: (accDur, blueprint) =>
      filterDefinitions.dur.hasFilterData(blueprint)
        ? [...accDur, blueprint.dur]
        : accDur,
    getLabel: sku => durationMessages[sku],
    sortCompare: (a, b) =>
      durationSortOrder.indexOf(a.id) - durationSortOrder.indexOf(b.id),
    expandedByDefault: true,
    joinType: anyPass,
  },
  certifications: {
    title: panelMessages.certifications,
    hasFilterData: blueprint =>
      blueprint.certifications && blueprint.certifications.length >= 0,
    appendFilterData: (accCertifications, blueprint) =>
      filterDefinitions.certifications.hasFilterData(blueprint)
        ? [
            ...accCertifications,
            ...blueprint.certifications.filter(
              certification => certificationMessages[certification]
            ),
          ]
        : accCertifications,
    getLabel: sku => certificationMessages[sku],
    sortCompare: (a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0),
    expandedByDefault: false,
    joinType: anyPass,
  },
  services: {
    title: panelMessages.services,
    hasFilterData: blueprint =>
      blueprint.services && blueprint.services.length >= 0,
    appendFilterData: (accServices, blueprint) =>
      filterDefinitions.services.hasFilterData(blueprint)
        ? [
            ...accServices,
            ...blueprint.services.filter(service => serviceMessages[service]),
          ]
        : accServices,
    getLabel: sku => serviceMessages[sku],
    sortCompare: (a, b) => (a.id < b.id ? -1 : a.id > b.id ? 1 : 0),
    expandedByDefault: false,
    joinType: anyPass,
  },
};

// Before editing these consider that it will affect our client-side metrics dashboards.
export const catalogMetricsNames = {
  filterPanelOpen: 'CatalogFilterPanelOpen',
  filterPanelClose: 'CatalogFilterPanelClose',
  filterPanelCheckboxToggle: 'CatalogFilterPanelCheckboxToggle',
  filterPanelSectionToggle: 'CatalogFilterPanelSectionToggle',
  filterPanelReset: 'CatalogFilterPanelReset',
};
