import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  TextContent,
  Box,
  Link,
  Alert,
  Header,
  SpaceBetween,
  Container,
} from '@amzn/awsui-components-react';

import { useConfigContext } from '../../contexts';
import appMessages from '../../i18n/app.messages';
import ProviderButton from './ProviderButton';
import messages from './SignInContainer.messages';

const SignInContainer = ({
  usingEmailAcl,
  primarySignInOption,
  secondarySignInOptions,
}) => {
  const config = useConfigContext();
  const { formatMessage } = useIntl();

  return (
    <div style={{ height: '100%' }}>
      <Box padding="xxxl">
        <SpaceBetween direction="vertical" size="xs">
          <Header variant="h1" headingTagOverride="h2">
            {formatMessage(messages.headerMessage)}
          </Header>
          <Container>
            <SpaceBetween direction="vertical" size="s">
              {usingEmailAcl ? (
                <Alert statusIconAriaLabel="Info">
                  {formatMessage(messages.alertMessage)}
                </Alert>
              ) : null}

              <ProviderButton
                idp={primarySignInOption}
                usingEmailAcl={usingEmailAcl}
              />

              {secondarySignInOptions && secondarySignInOptions.length ? (
                <>
                  <TextContent>
                    <Box textAlign="center">
                      {formatMessage(messages.orMessage)}
                    </Box>
                  </TextContent>
                  {secondarySignInOptions.map(option => {
                    return (
                      <ProviderButton
                        key={option}
                        idp={option}
                        usingEmailAcl={usingEmailAcl}
                      />
                    );
                  })}
                </>
              ) : null}
            </SpaceBetween>
          </Container>
          <Box float="right">
            <Link
              href={`https://${config.signInUIDomain}/help`}
              data-testid="help-link"
              external
              externalIconAriaLabel={formatMessage(
                appMessages.externalIconAriaLabel
              )}
            >
              {formatMessage(messages.getHelpLinkMessage)}
            </Link>
          </Box>
        </SpaceBetween>
      </Box>
    </div>
  );
};

SignInContainer.propTypes = {
  usingEmailAcl: PropTypes.bool.isRequired,
  primarySignInOption: PropTypes.string.isRequired,
  secondarySignInOptions: PropTypes.arrayOf(PropTypes.string),
};

export default SignInContainer;
