import { defineMessages } from 'react-intl';

const messages = defineMessages({
  defaultTitle: {
    id: 'landingPage.defaultTitle',
    defaultMessage: 'Welcome to <b>AWS\u00a0Builder\u00a0Labs</b>', // \u00a0 is the unicode representation of html entity - nbsp
  },
  defaultMessage: {
    id: 'landingPage.defaultMessage',
    defaultMessage: 'Sign in to build real skills in a live lab environment',
  },
  // EH and Jam copy
  ehAndJam_title: {
    id: 'landingPage.ehAndJam_title',
    defaultMessage: 'Take your skills to the next level',
  },
  ehAndJam_message: {
    id: 'landingPage.ehAndJam_message',
    defaultMessage:
      'Practice your skills (and learn new ones) with AWS Builder Labs and AWS Jam challenges.',
  },
  ehAndJam_labsTitle: {
    id: 'landingPage.ehAndJam_labsTitle',
    defaultMessage: 'AWS Builder Labs',
  },
  ehAndJam_labsDescription: {
    id: 'landingPage.ehAndJam_labsDescription',
    defaultMessage:
      'Practice using AWS services in a live sandbox environment. Choose a lab and follow step-by-step scenarios on specific topics. Allow 40-120 min per lab.',
  },
  ehAndJam_jamTitle: {
    id: 'landingPage.ehAndJam_jamTitle',
    defaultMessage: 'AWS Jam',
  },
  ehAndJam_jamDescription: {
    id: 'landingPage.ehAndJam_jamDescription',
    defaultMessage:
      'Play, Learn, Win! Choose from a menu of gamified real-life security challenges featuring AWS and its Partners. Compete on your own or with a team to secure your spot at the top of the leader board.',
  },
});

export default messages;
