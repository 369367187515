import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  SpaceBetween,
  StatusIndicator,
} from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import { propOr, prop } from 'ramda';

import './LabAssessmentContainer.scss';
import messages from './LabAssessmentContainer.messages';
import metrics from '../../../utils/metrics';
import appMessages from '../../../i18n/app.messages';
import image from '../../../images/assessment-icon.svg';
import useGetLabAssessment from '../../../hooks/useGetLabAssessment';
import useStartLabAssessment from '../../../hooks/useStartLabAssessment';
import {
  ASSESSMENT_UI_WINDOW_NAME,
  LAB_ASSESSMENT_STATUS,
} from '../../../constants/labAssessment';
import { dataTestId } from '../../../constants/dataTestIdSelectors';
import ERROR_TYPES from '../../../constants/errorTypes';
import { notificationTypes, useNotificationContext } from '../../../contexts';

const LabAssessmentContainer = ({ labId, assessmentId }) => {
  const { formatMessage } = useIntl();
  const metricsPublisher = useRef(metrics.createPublisher('LabAssessment'));
  const { appendNotification } = useNotificationContext();

  const {
    data: labAssessment,
    isLoading: isGettingLabAssessment,
    error: getLabAssessmentError,
  } = useGetLabAssessment({
    labId,
    assessmentId,
    metricsPublisher: metricsPublisher.current,
  });

  const {
    mutateAsync: startLabAssessment,
    isLoading: isStartingLabAssessment,
  } = useStartLabAssessment({
    metricsPublisher: metricsPublisher.current,
  });

  const handleStartLabAssessment = async () => {
    // Open blank window first to avoid pop-up blocker by browsers for async requests.
    const tab = window.open('about:blank', ASSESSMENT_UI_WINDOW_NAME);
    try {
      const labAssessment = await startLabAssessment({ labId, assessmentId });
      tab.open(labAssessment.url, '_self');
    } catch (err) {
      tab.close();
      for (const e of propOr([], 'errors', err)) {
        switch (prop('errorType', e)) {
          case ERROR_TYPES.Conflict: {
            appendNotification({
              contentId: messages.errorConflict,
              type: 'warning',
              notificationType: notificationTypes.LAB,
            });
            return;
          }
        }
      }
      appendNotification({
        contentId: messages.errorDefault,
        type: 'warning',
        notificationType: notificationTypes.LAB,
      });
    }
  };

  if (getLabAssessmentError) {
    return (
      <div
        className="LabAssessmentContainer"
        data-testid={`lab-assessment-${assessmentId}`}
      >
        <p>{formatMessage(messages.errorDefault)}</p>
        <div className="LabAssessmentContainer__action">
          <Button onClick={() => window.location.reload()}>
            {formatMessage(messages.reload)}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div
      className="LabAssessmentContainer"
      data-testid={`lab-assessment-${assessmentId}`}
    >
      <img src={image} alt="" className="LabAssessmentContainer__image" />
      <h3 className="LabAssessmentContainer__header">
        {formatMessage(messages.header)}
      </h3>
      <p>{formatMessage(messages.description)}</p>
      <p>
        {formatMessage(messages.why, {
          strong: chunk => <strong>{chunk}</strong>,
        })}
      </p>
      <p>
        {formatMessage(messages.note, {
          strong: chunk => <strong>{chunk}</strong>,
        })}
      </p>
      <div className="LabAssessmentContainer__action">
        {isGettingLabAssessment ? (
          <Button loading />
        ) : !labId || !labAssessment ? (
          <Button
            iconAlign="right"
            iconName="external"
            onClick={handleStartLabAssessment}
            ariaLabel={`${formatMessage(messages.launch)} ${formatMessage(
              appMessages.externalIconAriaLabel
            )}`}
            loading={isStartingLabAssessment}
            disabled={!labId}
            data-testid={dataTestId['labAssessment-launch']}
          >
            {formatMessage(messages.launch)}
          </Button>
        ) : labAssessment.status === LAB_ASSESSMENT_STATUS.NOT_STARTED ||
          labAssessment.status === LAB_ASSESSMENT_STATUS.IN_PROGRESS ? (
          <Button
            iconAlign="right"
            iconName="external"
            href={labAssessment.url}
            target={ASSESSMENT_UI_WINDOW_NAME}
            onClick={() =>
              metricsPublisher.current.publishCounter('AssessmentResumed', 1)
            }
            ariaLabel={`${formatMessage(messages.resume)} ${formatMessage(
              appMessages.externalIconAriaLabel
            )}`}
            data-testid={dataTestId['labAssessment-resume']}
          >
            {formatMessage(messages.resume)}
          </Button>
        ) : (
          <SpaceBetween direction="horizontal" alignItems="center" size="xs">
            <Button
              iconAlign="right"
              iconName="external"
              onClick={handleStartLabAssessment}
              ariaLabel={`${formatMessage(messages.retake)} ${formatMessage(
                appMessages.externalIconAriaLabel
              )}`}
              loading={isStartingLabAssessment}
              data-testid={dataTestId['labAssessment-retake']}
            >
              {formatMessage(messages.retake)}
            </Button>
            {labAssessment.status === LAB_ASSESSMENT_STATUS.PASSED ? (
              <span className="LabAssessmentContainer__success">
                <StatusIndicator type="success">
                  {formatMessage(messages.passed)}
                </StatusIndicator>
              </span>
            ) : labAssessment.status === LAB_ASSESSMENT_STATUS.FAILED ? (
              <span className="LabAssessmentContainer__error">
                <StatusIndicator type="error">
                  {formatMessage(messages.failed)}
                </StatusIndicator>
              </span>
            ) : null}
          </SpaceBetween>
        )}
      </div>
    </div>
  );
};

LabAssessmentContainer.propTypes = {
  labId: PropTypes.string,
  assessmentId: PropTypes.string.isRequired,
};

export default LabAssessmentContainer;
