import React from 'react';
import { Box, TextContent } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { propOr } from 'ramda';

import LoadingSkeleton from '../LoadingSkeleton';
import DurationItem from '../LabUI/LabInformation/DurationItem';
import { getUriForCatalogItem } from '../../utils/catalogUtils';
import { getLanguageCode } from '../../utils/localeUtils';
import { TOUR_IDS } from '../../constants/siteTour';
import messages from './CatalogListItem.messages';
import { dataTestId } from '../../constants/dataTestIdSelectors';
import { publishCounterMetric } from '../../utils/metrics';

const PlaceholderItem = ({ title }) => (
  <>
    <div className="awsui-util-hide">{title}</div>

    <Box margin={{ vertical: 'xs' }}>
      <LoadingSkeleton width={60} height={22} />
    </Box>

    <Box margin={{ vertical: 'xs' }}>
      <LoadingSkeleton width={100} height={40} />
    </Box>
    <LoadingSkeleton width={15} height={20} />
  </>
);

const isInProgress = (blueprintArn, ongoingArn) => {
  if (!ongoingArn) return false;
  if (blueprintArn === ongoingArn) return true;

  const getArnWithoutVersion = arn => arn.substring(0, arn.lastIndexOf(':'));
  const blueprintArnWithoutVersion = getArnWithoutVersion(blueprintArn);
  const ongoingArnWithoutVersion = getArnWithoutVersion(ongoingArn);

  if (
    blueprintArn === ongoingArnWithoutVersion ||
    blueprintArnWithoutVersion === ongoingArnWithoutVersion
  )
    return true;

  return false;
};

const CatalogListItem = ({
  blueprint,
  bpLocale,
  isLoading,
  ongoingBlueprintArn,
  ongoingLabId,
  searchScore,
  searchText,
}) => {
  const { formatMessage } = useIntl();
  const langAttr = getLanguageCode(propOr('', 'locale', bpLocale));

  const handleItemClick = () => {
    if (searchText) {
      publishCounterMetric('CatalogSearch', {
        counterName: 'Count',
        additionalMetrics: {
          [blueprint.arn]: searchText,
          // Search score is a value between 0 and 1, where 0 is a perfect match and 1 is no match.
          // Since Katal Metrics only allow integer values, score will be scaled by 1000.
          score: 1000 * searchScore,
        },
      });
    }
  };

  return (
    <Box padding={{ vertical: 'xs' }} margin={{ bottom: 'xs' }}>
      {isLoading ? (
        <PlaceholderItem title={formatMessage(messages.loading)} />
      ) : (
        <>
          <TextContent>
            <Link
              to={getUriForCatalogItem(
                blueprint.arn,
                bpLocale.locale,
                ongoingBlueprintArn,
                ongoingLabId
              )}
              data-testid={dataTestId['catalog-listItemLink']}
              data-tourid={TOUR_IDS.labPath}
              onClick={handleItemClick}
            >
              <Box
                variant="h2"
                fontWeight="normal"
                fontSize="heading-m"
                color="inherit"
              >
                <span lang={langAttr}>{bpLocale.title}</span>
              </Box>
            </Link>
          </TextContent>

          <Box margin={{ vertical: 'xs' }}>
            <span lang={langAttr}>{bpLocale.description}</span>
          </Box>

          <Box padding={{ bottom: 'xs' }} margin={{ bottom: 'xs' }}>
            <Box float="left">
              <DurationItem
                showLabel={true}
                duration={blueprint.durationData}
              />
            </Box>
            <Box float="right" color="text-status-info" variant="strong">
              {isInProgress(blueprint.arn, ongoingBlueprintArn)
                ? formatMessage(messages.inProgress)
                : ''}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

CatalogListItem.propTypes = {
  blueprint: PropTypes.object,
  bpLocale: PropTypes.object,
  isLoading: PropTypes.bool,
  ongoingBlueprintArn: PropTypes.string,
  ongoingLabId: PropTypes.string,
  searchScore: PropTypes.number,
  searchText: PropTypes.string,
};

export default CatalogListItem;
