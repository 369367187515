import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Grid } from '@amzn/awsui-components-react';

import { Footer } from '../components/Footer';
import SignInContainer from '../components/LandingPage/SignInContainer';
import EventInformation from '../components/LandingPage/EventInformation';
import { publishCounterMetric } from '../utils/metrics';
import SEO from '../components/SEO';
import { HeaderEH, HeaderWrapper } from '../components/Header';
import messages from './LandingPage.messages';
import { IdPs } from '../constants/idPs';
import Main from '../components/Main';
import logger from '../utils/logger';
import { LANDING_PAGE_VARIANTS } from '../constants/landingPage';
import './LandingPage.scss';
import { EHLandingPageConfig } from '../models/EHLandingPageConfig';
import { useConfigContext } from '../contexts';

/**
 * Landing page options are looked up using the event hostname. IDP list can be provided through config.json.
 * Hard coding this is a temporary measure until we can manage it async.
 *
 * @param {string} hostname
 * @param {string[]} configuredIdps Possible list of IDPs from event stack config
 * @returns {EHLandingPageConfig}
 */
const getLandingPageConfig = (hostname, configuredIdps = []) => {
  const defaultConfig = new EHLandingPageConfig({
    variant: LANDING_PAGE_VARIANTS.default,
    usingEmailAcl: false,
    primaryIdp: IdPs.OTP,
    secondaryIdps: [IdPs.AWSBuilderID, IdPs.AmazonFederate],
  });

  const ehAndJamConfig = new EHLandingPageConfig({
    variant: LANDING_PAGE_VARIANTS.ehAndJam,
    usingEmailAcl: true,
    primaryIdp: IdPs.AWSBuilderID,
  });

  // Update this list to add new custom event landing pages.
  const options = {
    ['localhost']: defaultConfig,
    ['reinforce.labs.awsevents.com']: ehAndJamConfig,
  };

  if (!options[hostname]) {
    logger.info(`No LP config for ${hostname}, using default.`);
  }
  const landingPageConfig = options[hostname] || defaultConfig;

  // Check if the EH config provides what IDPs to use, it will override any hard coded values.
  const [primaryIdp, ...secondaryIdps] = configuredIdps.filter(idp => {
    if (!IdPs[idp]) logger.warn(`Unknown LandingPage IDP: ${idp}`);
    return !!IdPs[idp];
  });

  if (primaryIdp) {
    logger.debug('Overriding landing page config', primaryIdp, secondaryIdps);
    // Makes sure to override both primaryIdp and secondaryIdps to set/reset the value
    return {
      ...landingPageConfig,
      primaryIdp,
      secondaryIdps,
    };
  }
  return landingPageConfig;
};

/**
 * @param {string} hostname The landing page layout depends on the given hostname.
 */
const LandingPage = ({ hostname }) => {
  const { formatMessage } = useIntl();
  const config = useConfigContext();
  useEffect(() => {
    publishCounterMetric('LandingPage');
  }, []);
  const landingPageConfig = getLandingPageConfig(
    hostname,
    config.landingPageIDPs
  );

  return (
    // Not using AppLayout because of missing vertical stretch mechanism. Will be supported in Cloudscape VR.
    // Related Cloudscape ticket for stretching AppLayout, https://issues.amazon.com/issues/AWSUI-18716
    <div className="LandingPage__wrapper">
      <SEO title={formatMessage(messages.seoTitle)} />
      <HeaderWrapper>
        <HeaderEH
          isAuthenticated={false}
          isStandalone={true}
          isOutsideRouter={true}
        />
      </HeaderWrapper>
      <Main className="LandingPage__content">
        <Grid
          disableGutters
          gridDefinition={[
            {
              colspan: {
                default: 12,
                m: 5,
                l: 4,
                xl: 3,
              },
            },
            {
              colspan: {
                default: 12,
                m: 6,
                xl: 4,
              },
            },
          ]}
        >
          <EventInformation variant={landingPageConfig.variant} />
          <SignInContainer
            usingEmailAcl={landingPageConfig.usingEmailAcl}
            primarySignInOption={landingPageConfig.primaryIdp}
            secondarySignInOptions={landingPageConfig.secondaryIdps}
          />
        </Grid>
      </Main>
      <Footer isStandalone isOutsideRouter appLayoutRef={{}} />
    </div>
  );
};

LandingPage.propTypes = {
  hostname: PropTypes.string.isRequired,
};

export default LandingPage;
