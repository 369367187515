import React from 'react';
import { useIntl } from 'react-intl';

import CookiePrefsButton from './CookiePrefsButton';
import { ButtonOverride } from '../PolarisOverrides';
import messages from './LegalZone.messages';
import { dataTestId } from '../../constants/dataTestIdSelectors';
import appMessages from '../../i18n/app.messages';

const LegalZone = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <ButtonOverride
        variant="transparent"
        href={'https://aws.amazon.com/privacy/'}
        target="_blank"
        data-testid={dataTestId['footer-privacy']}
        ariaLabel={`${formatMessage(messages.privacy)} ${formatMessage(
          appMessages.externalIconAriaLabel
        )}`}
      >
        {formatMessage(messages.privacy)}
      </ButtonOverride>
      <ButtonOverride
        variant="transparent"
        href={'https://aws.amazon.com/terms/'}
        target="_blank"
        data-testid={dataTestId['footer-siteTerms']}
        ariaLabel={`${formatMessage(messages.siteTerms)} ${formatMessage(
          appMessages.externalIconAriaLabel
        )}`}
      >
        {formatMessage(messages.siteTerms)}
      </ButtonOverride>
      <CookiePrefsButton />
    </>
  );
};

LegalZone.propTypes = {};

export default LegalZone;
