import React, { useState, useRef, useCallback } from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Link,
  Modal,
  Popover,
} from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import { useConfigContext, useLabContext } from '../../contexts';
import { handleSignIn } from '../../utils/authUtils';
import { dataTestId } from '../../constants/dataTestIdSelectors';
import messages from './LabButtons.messages';
import labEvalMessages from './LabEvaluation/LabEvaluation.messages';
import appMessages from '../../i18n/app.messages';
import { loadingStatuses } from '../../contexts/labState';
import ExpandableSupportInformation from '../../components/ExpandableSupportInformation';

export const StartLabButton = ({ isAuthenticated, onClick, ...props }) => {
  const config = useConfigContext();
  const { formatMessage } = useIntl();
  const [isOpen, setOpen] = useState(false);
  const [isAccepted, setAccepted] = useState(false);
  const startLabRef = useRef(null);

  if (config.enableActiveLabOnlyMode === 'true') return null;

  const closeModal = () => {
    if (startLabRef?.current) {
      startLabRef.current.focus();
    }
    setOpen(false);
  };
  const startLab = () => {
    onClick(isAccepted);
    setOpen(false);
  };
  return (
    <>
      {isOpen && (
        <Modal
          header={formatMessage(messages.acceptTermsModalHeader)}
          visible={isOpen}
          onDismiss={closeModal}
          closeAriaLabel={formatMessage(messages.closeLabel)}
          data-testid={dataTestId['lab-startDialog']}
          footer={
            <Box variant="span" float="right">
              <Button variant="link" onClick={closeModal}>
                {formatMessage(messages.closeBtn)}
              </Button>
              <Button
                variant="primary"
                onClick={startLab}
                disabled={!isAccepted}
                data-testid={dataTestId['lab-startAcceptBtn']}
              >
                {formatMessage(messages.startLabBtn)}
              </Button>
            </Box>
          }
        >
          <Checkbox
            checked={isAccepted}
            onChange={() => setAccepted(!isAccepted)}
            data-testid={dataTestId['lab-startAcceptChbx']}
          >
            {formatMessage(messages.acceptTermsModalMessage, {
              aa: chunk => (
                <Link
                  href="https://aws.amazon.com/legal/learner-terms-conditions/"
                  variant="primary"
                  external
                  externalIconAriaLabel={formatMessage(
                    appMessages.externalIconAriaLabel
                  )}
                >
                  {chunk}
                </Link>
              ),

              ab: chunk => (
                <Link
                  href="https://aws.amazon.com/privacy/?src=aws_training"
                  variant="primary"
                  external
                  externalIconAriaLabel={formatMessage(
                    appMessages.externalIconAriaLabel
                  )}
                >
                  {chunk}
                </Link>
              ),
            })}
          </Checkbox>
        </Modal>
      )}
      <Button
        {...props}
        variant="primary"
        onClick={isAuthenticated ? () => setOpen(true) : handleSignIn}
        data-testid={dataTestId['lab-startBtn']}
        ref={buttonRef => (startLabRef.current = buttonRef)}
      >
        {formatMessage(messages.startLabBtn)}
      </Button>
    </>
  );
};

// Returns object of messages used in the end lab modal
// Dependent on hasLabEvaluation, hasCheckedLabEvaluationScore, hasSubmittedLabEvaluationScore
const endLabModalMessageKey = (
  hasLabEvaluation,
  hasCheckedLabEvaluationScore,
  hasSubmittedLabEvaluationScore,
  hasSubmissionDisabled
) => {
  const commonStrings = {
    closeLabel: messages.closeLabel,
    closeBtn: messages.closeBtn,
  };

  const defaultMessages = {
    endModalHeader: messages.endModalHeader,
    endLabBtn: messages.endLabBtn,
    endModalMessage: messages.endModalMessage,
    ...commonStrings,
  };

  // check if lab evaluation is enabled
  if (hasLabEvaluation) {
    // check if score submission disabled
    if (hasSubmissionDisabled) return defaultMessages;

    // lab eval score has been submitted at least once
    if (hasSubmittedLabEvaluationScore) {
      return {
        endModalHeader: labEvalMessages.submittedScoreEndLabModalHeader,
        endLabBtn: labEvalMessages.submittedScoreEndLabModalButton,
        endModalMessage: labEvalMessages.submittedScoreEndLabModalContent,
        ...commonStrings,
      };
    }

    // lab eval score has been checked but not submitted
    if (hasCheckedLabEvaluationScore && !hasSubmittedLabEvaluationScore) {
      return {
        endModalHeader: labEvalMessages.noSubmittedScoreEndLabModalHeader,
        endLabBtn: labEvalMessages.noSubmittedScoreEndLabModalButton,
        endModalMessage: labEvalMessages.noSubmittedScoreEndLabModalContent,
        ...commonStrings,
      };
    }

    // lab eval score not checked and not scored
    return {
      endModalHeader: labEvalMessages.noCheckedScoreEndLabModalHeader,
      endLabBtn: labEvalMessages.noCheckedScoreEndLabModalButton,
      endModalMessage: labEvalMessages.noCheckedScoreEndLabModalContent,
      ...commonStrings,
    };
  }

  // default end lab modal messages (no lab eval)
  return defaultMessages;
};

export const EndLabButton = ({
  hasLabEvaluation,
  hasCheckedLabEvaluationScore,
  hasSubmittedLabEvaluationScore,
  hasSubmissionDisabled,
  onClick,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const [isOpen, setOpen] = useState(false);
  const endLabRef = useRef(null);
  const closeModal = () => {
    setOpen(false);
    if (endLabRef?.current) {
      endLabRef.current.focus();
    }
  };
  const endLab = () => {
    onClick();
    closeModal();
  };

  const endLabModalMessages = endLabModalMessageKey(
    hasLabEvaluation,
    hasCheckedLabEvaluationScore,
    hasSubmittedLabEvaluationScore,
    hasSubmissionDisabled
  );

  return (
    <>
      {isOpen && (
        <Modal
          header={formatMessage(endLabModalMessages.endModalHeader)}
          visible={isOpen}
          onDismiss={closeModal}
          closeAriaLabel={formatMessage(endLabModalMessages.closeLabel)}
          footer={
            <Box variant="span" float="right">
              <Button variant="link" onClick={closeModal}>
                {formatMessage(endLabModalMessages.closeBtn)}
              </Button>
              <Button
                variant="primary"
                onClick={endLab}
                data-testid={dataTestId['lab-endConfirmBtn']}
              >
                {formatMessage(endLabModalMessages.endLabBtn)}
              </Button>
            </Box>
          }
        >
          {formatMessage(endLabModalMessages.endModalMessage)}
        </Modal>
      )}
      <Box margin={{ right: 's' }}>
        <Button
          {...props}
          variant={props.variant || 'normal'}
          onClick={() => setOpen(true)}
          data-testid={dataTestId['lab-endBtn']}
          ref={buttonRef => (endLabRef.current = buttonRef)}
        >
          {formatMessage(messages.endLabBtn)}
        </Button>
      </Box>
    </>
  );
};

export const OpenConsoleButton = ({ isProvisioning = false, ...props }) => {
  const { formatMessage } = useIntl();
  const buttonComponent = (
    <Button
      {...props}
      variant="primary"
      iconAlign="right"
      iconName="external"
      data-testid={dataTestId['lab-openConsoleBtn']}
    >
      {formatMessage(messages.openConsoleBtn)}
    </Button>
  );

  if (isProvisioning)
    return (
      <Popover
        position="bottom"
        dismissButton={false}
        triggerType="custom"
        content={formatMessage(messages.disabledOpenConsoleProvisioning)}
      >
        {buttonComponent}
      </Popover>
    );

  return buttonComponent;
};

export const OpenLabEvaluationPanelButton = ({
  hasLabEvaluation,
  isLabEvaluationPanelShown,
  onClick,
  ...props
}) => {
  const { formatMessage } = useIntl();
  if (!hasLabEvaluation || isLabEvaluationPanelShown) return null;

  return (
    <Box margin={{ right: 's' }}>
      <Button
        {...props}
        variant="normal"
        onClick={onClick}
        data-testid={dataTestId['lab-labEvaluationPanelBtn']}
      >
        {formatMessage(messages.openLabEvaluationPanelButton)}
      </Button>
    </Box>
  );
};

export const LabProgressTrackingButton = ({
  hasLabEvaluation,
  currentEvalSubmissionStatus,
  ...props
}) => {
  const config = useConfigContext();
  const { submitLabEvaluation, getFirstLabInLabState } = useLabContext();
  const labState = getFirstLabInLabState();
  const labId = labState?.labId;
  const { formatMessage } = useIntl();
  const [isOpen, setOpen] = useState(false);
  const selfCompleteLabRef = useRef(null);

  const isSubmitting = currentEvalSubmissionStatus === loadingStatuses.LOADING;
  const isError = currentEvalSubmissionStatus === loadingStatuses.ERROR;

  const handleSubmitLabEvaluation = useCallback(() => {
    submitLabEvaluation({
      labId,
      progressTrackingOnSuccess: closeModal,
    });
  }, [submitLabEvaluation, labId]);

  if (config.enableProgressTracking !== 'true') return null;
  if (hasLabEvaluation) return null; // no progress tracking flow for blueprints with lab eval

  const closeModal = () => {
    setOpen(false);
    if (selfCompleteLabRef?.current) {
      selfCompleteLabRef.current.focus();
    }
  };

  return (
    <>
      {isOpen && (
        <Modal
          header={formatMessage(messages.progressTrackingModalHeader)}
          visible={isOpen}
          onDismiss={closeModal}
          closeAriaLabel={formatMessage(messages.closeLabel)}
          footer={
            <Box variant="span" float="right">
              <Button variant="link" onClick={closeModal}>
                {formatMessage(messages.closeBtn)}
              </Button>
              <Button
                onClick={handleSubmitLabEvaluation}
                disabled={isSubmitting}
                loading={isSubmitting}
                data-testid={dataTestId['lab-progressTrackingConfirmBtn']}
              >
                {formatMessage(messages.progressTrackingBtn)}
              </Button>
            </Box>
          }
        >
          {isError && (
            <Box margin={{ bottom: 's' }}>
              <Alert type="warning">
                {formatMessage(messages.progressTrackingFailure)}
                <ExpandableSupportInformation
                  expandedInfo={formatMessage(
                    labEvalMessages.supportInfoMessage
                  )}
                  copyableInfo={`Error: EvaluationRequestFailed\nLabId: ${labId}\nLabURL: ${document.location.href}`}
                />
              </Alert>
            </Box>
          )}
          <Box margin={{ bottom: 'xs' }}>
            {formatMessage(messages.progressTrackingMessage)}
          </Box>
        </Modal>
      )}
      <Box margin={{ right: 's' }}>
        <Button
          {...props}
          onClick={() => setOpen(true)}
          ref={buttonRef => (selfCompleteLabRef.current = buttonRef)}
          data-testid={dataTestId['lab-progressTrackingBtn']}
        >
          {formatMessage(messages.progressTrackingBtn)}
        </Button>
      </Box>
    </>
  );
};
