import React, { useMemo, useState } from 'react';
import { Box, Button, Container, Modal } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import messages from './SkillBuilderBanner.messages';
import { REINVENT_EVENT_NAME, SKILL_BUILDER_LINK } from './constants';
import { useConfigContext, useAuthContext } from '../../contexts';
import { publishCounterMetric } from '../../utils/metrics';
import { getEHLocationValue } from '../../utils/configureAmplify';
import appMessages from '../../i18n/app.messages';
import logoImage from '../../images/aws-builder-labs.svg';

import './SkillBuilderBanner.scss';

const SkillBuilderBanner = () => {
  const { formatMessage } = useIntl();
  const config = useConfigContext();
  const location = useLocation();
  const [{ vibeId }] = useAuthContext();
  const [modalVisible, setModalVisible] = useState(false);

  const isVirtual = useMemo(() => !getEHLocationValue(), []);

  if (config.eventName !== REINVENT_EVENT_NAME) return null;

  const publishMetrics = () => {
    publishCounterMetric('SkillBuilderBanner', {
      counterName: isVirtual ? 'LinkOpened' : 'ButtonClicked',
      additionalMetrics: {
        CurrentPath: location.pathname,
        VibeId: vibeId,
      },
    });
  };

  return (
    <Container className="SkillBuilderBanner">
      <img src={logoImage} alt="" className="SkillBuilderBanner__logo" />
      <Box variant="h2" className="SkillBuilderBanner__header">
        {formatMessage(messages.header)}
      </Box>
      <Box variant="p" className="SkillBuilderBanner__body">
        {formatMessage(messages.description)}
      </Box>
      {isVirtual && (
        <Box variant="p" className="SkillBuilderBanner__body">
          {formatMessage(messages.warning)}
        </Box>
      )}
      <Box margin={{ top: 's' }}>
        {isVirtual ? (
          <Button
            href={SKILL_BUILDER_LINK}
            iconAlign="right"
            iconName="external"
            target="_blank"
            onClick={publishMetrics}
            ariaLabel={`${formatMessage(messages.button)} ${formatMessage(
              appMessages.externalIconAriaLabel
            )}`}
          >
            {formatMessage(messages.button)}
          </Button>
        ) : (
          <Button
            onClick={() => {
              setModalVisible(true);
              publishMetrics();
            }}
          >
            {formatMessage(messages.button)}
          </Button>
        )}
      </Box>
      {!isVirtual && (
        <Modal
          visible={modalVisible}
          header={formatMessage(messages.header)}
          closeAriaLabel={formatMessage(messages.closeAriaLabel)}
          onDismiss={() => setModalVisible(false)}
        >
          {formatMessage(messages.modal)}
        </Modal>
      )}
    </Container>
  );
};

export default SkillBuilderBanner;
