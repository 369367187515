import React from 'react';
import PropTypes from 'prop-types';
import {
  ChatBot as AiChatBot,
  useEHChatBotVisibility,
} from '@amzn/aws-tc-ai-coaching-frontend';
import { isNil } from 'ramda';

import ChatBotErrorBoundary from './ChatBotErrorBoundary';
import { showChatBotOnLabPage } from './utils';
import { useEventContext, useLocaleContext } from '../../contexts';
import { getBlueprintName } from '../../utils/blueprintUtils';
import { dataTestId } from '../../constants/dataTestIdSelectors';

const ChatBot = ({ blueprintArn, gandalfToken }) => {
  const { event } = useEventContext();
  const [userLocale] = useLocaleContext();
  const { shouldRenderChatBot } = useEHChatBotVisibility();

  if (isNil(blueprintArn) || isNil(gandalfToken)) return null;
  if (
    !showChatBotOnLabPage({
      eventContext: event,
      blueprintName: getBlueprintName(blueprintArn),
    }) ||
    !shouldRenderChatBot(userLocale)
  ) {
    return null;
  }

  return (
    <span data-testid={dataTestId['ai-chatbot']}>
      <ChatBotErrorBoundary>
        <AiChatBot blueprintArn={blueprintArn} gandalfToken={gandalfToken} />
      </ChatBotErrorBoundary>
    </span>
  );
};

ChatBot.propTypes = {
  blueprintArn: PropTypes.string,
  gandalfToken: PropTypes.string,
};

export default ChatBot;
