import { AWSCShortbread } from '@amzn/shortbread';
import { Hub } from '@aws-amplify/core';

import metrics from './metrics';
import { IMPRESSIONS } from '../constants/metrics';
import {
  PROD_DOMAINS,
  METRICS_METHOD,
  COOKIE_PREFS_SELECTOR,
  SHORTBREAD_PREFS_SELECTOR,
  COOKIE_REGISTRY,
} from '../constants/cookies';
import logger from './logger';
import { COOKIE_CONSENT_CHANGED } from '../constants/pubSub';

const isLocalhost = window.location.hostname === 'localhost';

export const getCookieDomain = hostname => {
  for (const prodDomain of PROD_DOMAINS) {
    if (hostname.indexOf(prodDomain) !== -1) return prodDomain;
  }

  // If the domain is not found in prod, return the requested hostname to avoid setting the wrong domain.
  return hostname;
};

/**
 * Used to force the cookie banner when testing in localhost without having to connect to a EU VPN.
 * The native shortbread queryGeolocation function will be used otherwise.
 */
const replaceGeolocationFn = isLocalhost
  ? geolocatedIn => geolocatedIn('EU')
  : undefined;

const isVisible = element => {
  return element.offsetWidth > 0 && element.offsetHeight > 0;
};

const focusCookiePreferences = () => {
  const $shortbreadButton = document.querySelector(SHORTBREAD_PREFS_SELECTOR);
  if ($shortbreadButton && isVisible($shortbreadButton)) {
    $shortbreadButton.focus();
  } else {
    const $button = document.querySelector(COOKIE_PREFS_SELECTOR);
    if ($button) $button.focus();
  }
};

// Cache the shortbread instance
let shortbreadInstance;
let metricsPublisher;

/**
 * @param {[string]} locale
 * @param {[boolean]} createNew
 * @returns {AWSCShortbread} Shortbread instance
 */
const getShortbread = (locale = 'en-US', createNew = false) => {
  if (!createNew && shortbreadInstance) return shortbreadInstance;

  if (!metricsPublisher) {
    metricsPublisher = metrics.createPublisher(METRICS_METHOD);
  }

  shortbreadInstance = AWSCShortbread({
    domain: getCookieDomain(window.location.hostname),
    language: locale,
    registry: COOKIE_REGISTRY,
    queryGeolocation: replaceGeolocationFn,
    onBannerShown: () => metricsPublisher.publishCounter(IMPRESSIONS, 1),
    onModalClose: () => focusCookiePreferences(),
    onConsentChanged: definitions => {
      Hub.dispatch(COOKIE_CONSENT_CHANGED, definitions);
    },
  });
  return shortbreadInstance;
};

/**
 * Displays the cookie banner if not already accepted. The resulting cookie is named `awsccc`.
 * Note. The banner will only show for EU ip addresses, connect to the Dublin or Frankfurt VPN to test and verify.
 * See https://w.amazon.com/bin/view/Shortbread for more info.
 *
 * @returns {Promise}
 */
export const checkForCookieConsent = () => {
  const shortbread = getShortbread();
  shortbread.checkForCookieConsent();
};

/**
 * Displays the cookie banner preference UI.
 *
 * @returns {Promise}
 */
export const customizeCookies = () => {
  const shortbread = getShortbread();
  shortbread.customizeCookies();
};

/**
 * Recreates shortbread with specified locale.
 *
 * @returns {Promise}
 */
export const changeShortbreadLanguage = (locale = 'en-US') => {
  const shortbread = getShortbread(locale, true);
  shortbread.checkForCookieConsent();
};

/**
 * @param {string} cookieKey
 * @returns {boolean}
 */
export const hasShortbreadConsent = cookieKey => {
  const shortbread = getShortbread();
  return shortbread.hasConsent(cookieKey);
};

/**
 * Wraps the Storage API and ensures its usage conforms to
 * the customers configured Shortbread cookie consent.
 */
const createStorageAdapter = storage => {
  return {
    /**
     * @param {string} storageName
     * @param {string} value
     */
    setItem(storageName, value) {
      try {
        if (hasShortbreadConsent(storageName)) {
          return storage.setItem(storageName, value);
        } else {
          logger.debug(
            'setItem was denied consent for storageName:',
            storageName
          );
        }
      } catch (error) {
        logger.debug('setItem failed for storageName:', storageName, error);
      }
      storage.removeItem(storageName);
    },

    /**
     * @param {string} storageName
     */
    getItem(storageName) {
      try {
        if (hasShortbreadConsent(storageName)) {
          return storage.getItem(storageName);
        } else {
          logger.debug(
            'getItem cookie consent denied for storageName:',
            storageName
          );
        }
      } catch (error) {
        logger.debug('getItem failed for storageName:', storageName, error);
      }
      storage.removeItem(storageName);
      return null;
    },

    /**
     * @param {string} storageName
     */
    removeItem(storageName) {
      try {
        storage.removeItem(storageName);
      } catch (error) {
        logger.debug('removeItem failed for key:', storageName, error);
      }
    },
  };
};

export const localStorageAdapter = createStorageAdapter(localStorage);
export const sessionStorageAdapter = createStorageAdapter(sessionStorage);
