import React from 'react';
import { Container } from '@amzn/awsui-components-react';
import './ContainerOverride.scss';

const ContainerOverride = ({ variant, ...props }) => {
  if (variant === 'flashy')
    return (
      <span className={`ContainerOverride--flashy`}>
        <Container {...props} />
      </span>
    );

  return <Container variant={variant} {...props} />;
};

export default ContainerOverride;
